import React, { useCallback } from 'react'
import { Form, Formik, FormikErrors } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, Checkbox, Label } from './ui'
import { TextField } from './forms'

import type { UserForm } from '../types'

import RecipientsList from './RecipientsList'
import { RecipientResponse, ReportTypeListResponse } from 'api/types'

type UserProps = {
    values: UserForm
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => Promise<void | FormikErrors<UserForm>>
    isSubmitting: boolean
    readonly?: boolean
    handleRemove: (val: number) => void
    handleUpdate: (val: number) => void
    reportTypes: ReportTypeListResponse
    lastRecipientId: number
    recipients: RecipientResponse[]
}

export default function UserReportSettings({
    values,
    setFieldValue,
    isSubmitting,
    readonly = false,
    handleRemove,
    handleUpdate,
    lastRecipientId,
    reportTypes,
    recipients,
}: UserProps) {
    const { t } = useTranslation()

    const switchReportToggle = useCallback(
        (reportId: number) => {
            const index = values.reports.indexOf(reportId)
            setFieldValue(
                'reports',
                index !== -1
                    ? values.reports.filter((rep) => rep !== reportId)
                    : [...values.reports, reportId]
            )
        },
        [setFieldValue, values.reports]
    )

    const recipientsListData = [
        ...recipients.map(({ email, id, created_at }) => ({
            email: email,
            id: id,
            created_at: new Date(created_at),
        })),
        ...values.optimistic_recipients.map(({ email }, index) => ({
            email: email,
            id: lastRecipientId + index,
            created_at: new Date(),
        })),
    ]

    return (
        <div className="mt-8">
            <Form>
                <div>
                    <div className="border-t border-gray-600 my-5"></div>
                    <div className="text-lg leading-8 font-medium">
                        {t('form.user.tabs.report')}
                    </div>
                    <div className="pb-5">
                        {t('form.user.report.description')}
                    </div>

                    <div className="mr-auto max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4 pb-3 pl-4">
                        {reportTypes.map(({ id, name }) => (
                            <div className="col-span-1">
                                <Checkbox
                                    name={t(`form.user.report.${name}`)}
                                    value={values.reports.indexOf(id) !== -1}
                                    disabled={readonly}
                                    onChange={() => switchReportToggle(id)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Form>
            {!readonly && (
                <div className="pb-14">
                    <Formik<{ email_report: string }>
                        initialValues={{ email_report: '' }}
                        onSubmit={(
                            subFormValues,
                            { setSubmitting, resetForm }
                        ) => {
                            setFieldValue('optimistic_recipients', [
                                ...values.optimistic_recipients,
                                { email: subFormValues.email_report },
                            ])
                            setSubmitting(false)
                            resetForm()
                        }}
                    >
                        {() => (
                            <Form>
                                <div className="border-t border-gray-600 pt-5"></div>
                                <div className="text-lg leading-8 font-medium">
                                    {t('form.user.report.send_to')}
                                </div>
                                <div className="pb-11">
                                    {t('form.user.report.send_to_description')}
                                </div>

                                <div className="mr-auto max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="col-span-1">
                                        <Label>
                                            {t('form.user.field.email')}
                                        </Label>
                                        <TextField name="email_report" />
                                    </div>
                                    <div className="col-span-1 mt-auto">
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="ml-3"
                                            loading={isSubmitting}
                                        >
                                            {t('form.user.add_email')}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}

            <>
                <div className="border-t border-gray-600 my-5"></div>
                <div>
                    <span className="inline-block text-lg leading-6 font-medium pb-5">
                        {t('form.user.title.recipients')}
                    </span>
                    <RecipientsList
                        filters={{ search_global: '', length: 1 }}
                        filterCount={0}
                        handleRemove={handleRemove}
                        handleUpdate={handleUpdate}
                        emailsQuery={
                            {
                                data: {
                                    data: recipientsListData,
                                    meta: {
                                        current_page: 1,
                                        per_page: 2,
                                        from: 1,
                                        to: 1,
                                        last_page: 1,
                                        total: recipientsListData.length,
                                    },
                                },
                                isSuccess: true,
                                isError: false,
                                isFetched: true,
                            } as any
                        }
                    />
                </div>
            </>
            {!readonly && (
                <Form>
                    <div className="flex mt-8 pt-5 items-center justify-between">
                        <div className="flex-1"></div>
                        <div className="flex-1 flex items-center justify-end ml-3">
                            <Button variant="secondary" as="link" to="/user">
                                {t('form.cancel')}
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                className="ml-3"
                                loading={isSubmitting}
                            >
                                {t('form.save')}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    )
}
