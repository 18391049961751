import { useQuery, useMutation } from 'react-query'
import qs from 'qs'
import type {
    UseQueryOptions,
    UseQueryResult,
    UseMutationOptions,
} from 'react-query'

import client from '../client'
import { QUERY_CACHE_SERVICEPIN_KEY } from '../../constants/index'

import type {
    ResponseData,
    ResponseError,
    ServicePinResponse,
    GenerateServicePinVariables,
    DeleteVariables,
} from '../types'

export const getServicePin = <T>(filters?: {}): Promise<T> =>
    client.get('/user-codes' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useServicePinQuery = <
    T = ResponseData<ServicePinResponse | undefined>,
>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_SERVICEPIN_KEY, 'index', filters],
        () => getServicePin<T>(filters),
        options
    )

const creatgenerateServicePin = ({
    data,
}: GenerateServicePinVariables): Promise<ResponseData<ServicePinResponse>> =>
    client.post('/user-codes', data)

export const useGenerateServicePin = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<ServicePinResponse>,
            ResponseError,
            GenerateServicePinVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => creatgenerateServicePin(data), options)

const disableServicePin = ({
    id,
}: DeleteVariables): Promise<ResponseData<void>> =>
    client.patch(`/user-codes/${id}`, { active: false })

export const useDisableServicePin = (
    options?: Omit<
        UseMutationOptions<ResponseData<void>, ResponseError, DeleteVariables>,
        'mutationFn'
    >
) => useMutation((data) => disableServicePin(data), options)
