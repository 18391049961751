import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TabPanel } from '@headlessui/react'
import { Formik } from 'formik'

import { QUERY_CACHE_USERS_KEY } from '../constants/index'
import { useCreateUser, useReportTypesQuery } from '../api'
import { useFlashMessage, useModal, useSupervisorsDropdown } from '../hooks'
import PageLayout from '../components/PageLayout'
import User from '../components/User'
import UserTabView from '../components/UserTabView'
import UserFeatures from '../components/UserFeatures'
import { LANGUAGES } from '../constants/languages'

import type { UserForm, FormSubmitFn, RecipientForm } from '../types'
import { useQueryClient } from 'react-query'
import { usePayloadFromUserForm, useUserInitialValues } from '../hooks/form'
import { CreateUserRequest } from '../api/types'
import { Loader } from 'components/ui'
import UserReportSettings from 'components/UserReportSettings'
import ConfirmModal from 'components/ConfirmModal'
import RecipientModal from 'components/RecipientModal'

export default function NewUserView() {
    const { t } = useTranslation()

    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const navigate = useNavigate()
    const createUserQuery = useCreateUser({
        onSuccess: async () => {},
    })

    const userDropdownValues = useSupervisorsDropdown()
    const initialValues: UserForm = useUserInitialValues()
    const reportTypesQuery = useReportTypesQuery()

    const deleteRecipientModal = useModal<number>()
    const updateRecipientModal = useModal<number>()

    const payloadFromUserForm = usePayloadFromUserForm<CreateUserRequest>()
    const handleSubmit: FormSubmitFn<UserForm> = (values, formikHelpers) => {
        createUserQuery.mutate(
            {
                data: payloadFromUserForm(values),
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.user.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    navigate('/user')
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_USERS_KEY,
                        'index',
                    ])
                },
                onError: (error, variables) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)

                    const recipientError = Object.keys(error.errors).find(
                        (key) => key.match('recipients.add')
                    )
                    if (!!recipientError) {
                        const index = parseInt(
                            recipientError.replace(/^\D+/g, '')
                        )
                        flashMessage({
                            type: 'danger',
                            content: error.errors[recipientError].replace(
                                recipientError,
                                variables.data.recipients?.add[index].email ??
                                    ''
                            ),
                        })
                    }
                },
            }
        )
    }

    const lastRecipientId = 1

    if (reportTypesQuery.isLoading) {
        return <Loader />
    }

    if (reportTypesQuery.isError) {
        return (
            <div className="text-red-600">{reportTypesQuery.error.message}</div>
        )
    }

    if (!reportTypesQuery.isSuccess) {
        return null
    }

    return (
        <PageLayout
            title={t('form.user.new.title')}
            breadCrumbs={[
                {
                    name: t('bread.user_list'),
                    url: '/user',
                },
                {
                    name: t('form.user.new.title'),
                },
            ]}
        >
            <Formik<UserForm>
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, isSubmitting }) => {
                    const recipientData = values.optimistic_recipients.find(
                        (_, index) =>
                            lastRecipientId + index ===
                            updateRecipientModal.getState()!
                    )

                    const onUpdateRecipientSubmit = (
                        formValues: RecipientForm
                    ) => {
                        const index = values.optimistic_recipients.findIndex(
                            (_, index) =>
                                lastRecipientId + index ===
                                updateRecipientModal.getState()!
                        )
                        setFieldValue(
                            'optimistic_recipients',
                            values.optimistic_recipients.map(
                                (recipient, ind) =>
                                    ind === index ? formValues : recipient
                            )
                        )
                        updateRecipientModal.closeModal()
                    }

                    const onDeleteRecipientSubmit = () => {
                        const id = deleteRecipientModal.getState()!
                        setFieldValue(
                            'values.optimistic_recipients',
                            values.optimistic_recipients.splice(
                                lastRecipientId - id,
                                1
                            )
                        )
                        deleteRecipientModal.closeModal()
                    }
                    return (
                        <>
                            <UserTabView
                                haveUsers={false}
                                haveEmployees={false}
                                haveReports={false}
                            >
                                <TabPanel>
                                    <User
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        isSubmitting={isSubmitting}
                                        supervisors={userDropdownValues}
                                        languages={LANGUAGES}
                                        plottersList={[]}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <UserFeatures
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        isSubmitting={isSubmitting}
                                    />
                                </TabPanel>
                                <TabPanel></TabPanel>
                                <TabPanel>
                                    <UserReportSettings
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        isSubmitting={isSubmitting}
                                        reportTypes={reportTypesQuery.data.data}
                                        handleRemove={(id: number) => {
                                            deleteRecipientModal.setState(id)
                                            deleteRecipientModal.openModal()
                                        }}
                                        handleUpdate={(id: number) => {
                                            updateRecipientModal.setState(id)
                                            updateRecipientModal.openModal()
                                        }}
                                        recipients={[]}
                                        lastRecipientId={lastRecipientId}
                                    />
                                </TabPanel>
                            </UserTabView>
                            <ConfirmModal
                                modal={deleteRecipientModal}
                                title={t('delete.recipient.title')}
                                description={t('delete.recipient.description')}
                                actionLabel={t('action.delete')}
                                onSubmit={onDeleteRecipientSubmit}
                                isSubmitting={false}
                            />
                            <RecipientModal
                                modal={updateRecipientModal}
                                recipientData={recipientData}
                                onSubmit={onUpdateRecipientSubmit}
                            />
                        </>
                    )
                }}
            </Formik>
        </PageLayout>
    )
}
