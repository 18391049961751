import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import type { UseQueryResult } from 'react-query'
import { usePopperTooltip } from 'react-popper-tooltip'
import moment from 'moment'

import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/solid'

import type {
    UsedComplaintCodesListFilterState,
    UsedComplaintCodesListFilterAction,
} from '../types'
import type {
    ResponseList,
    UsedComplaintListResponse,
    UsedComplaintResponse,
} from '../api/types'
import { useProtectionFilmTypeQuery } from 'api'

import { Button3dots, Spinner } from './ui'
import TableFooter from './TableFooter'
import {
    TableHeader,
    TableContainer,
    Table,
    Tbody,
    Tempty,
    Tr,
} from './ui/List'

import popperStyles from './ui/Tooltip.module.scss'
import Menu3dots from './Menu3dots'
import { PresentationChartBarIcon } from '@heroicons/react/outline'

type UsedComplaintCodesListProps = {
    usedComplaintCodesQuery: UseQueryResult<
        ResponseList<UsedComplaintListResponse>
    >
    filters: UsedComplaintCodesListFilterState
    filterCount: number
    filterAction: React.Dispatch<UsedComplaintCodesListFilterAction>
}

type TableProps = {
    data: UsedComplaintListResponse
}

const UsedComplaintCodesList: React.FC<UsedComplaintCodesListProps> = ({
    usedComplaintCodesQuery,
    filters,
    filterCount,
    filterAction,
}) => {
    const { t } = useTranslation()

    return (
        <TableContainer>
            <Table>
                <TableHeader>
                    <th
                        scope="col"
                        className="w-1 min-w-[142px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.used_complaint.data')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[116px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.used_complaint.code')}
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.used_complaint.creator')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[185px] px-6 py-4 text-left tracking-wider "
                    >
                        {t('list.used_complaint.device')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[180px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.used_complaint.foil_type')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[164px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.used_complaint.pattern')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[204px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.used_complaint.feedback')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[144px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.used_complaint.localization')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[65px] px-6 py-4 text-left tracking-wider"
                    ></th>
                </TableHeader>
                {usedComplaintCodesQuery.isSuccess &&
                    usedComplaintCodesQuery.isFetched && (
                        <>
                            {usedComplaintCodesQuery.data.meta.total > 0 && (
                                <TableBody
                                    data={usedComplaintCodesQuery.data.data}
                                />
                            )}
                        </>
                    )}
                {usedComplaintCodesQuery.data?.meta.total === 0 && (
                    <Tbody>
                        <Tr key={-1} index={0}>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4 relative h-12">
                                {filterCount === 0 && (
                                    <Tempty>
                                        {t('list.used_complaint.empty_list')}
                                    </Tempty>
                                )}
                                {filterCount > 0 && (
                                    <Tempty>
                                        {t('list.used_complaint.empty_search')}
                                    </Tempty>
                                )}
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                        </Tr>
                    </Tbody>
                )}
            </Table>
            {usedComplaintCodesQuery.isLoading && (
                <div className="divide-y divide-gray-700 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (_, index) => (
                            <div
                                key={index}
                                className={classNames('relative h-[68px]', {
                                    'bg-gray-800': index % 2,
                                    'bg-background': !(index % 2),
                                })}
                            >
                                {index === 4 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            <TableFooter
                meta={usedComplaintCodesQuery.data?.meta}
                filterAction={filterAction}
            />
        </TableContainer>
    )
}

const TableBody: React.FC<TableProps> = ({ data }) => {
    return (
        <Tbody>
            {data.map((item, index) => (
                <TableRow item={item} index={index} />
            ))}
        </Tbody>
    )
}

const TableRow = ({
    item,
    index,
}: {
    item: UsedComplaintResponse
    index: number
}) => {
    const { t } = useTranslation()

    const protectionFilmsTypesQuery = useProtectionFilmTypeQuery()

    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({
        placement: 'top-start',
    })

    return (
        <Tr key={item.id} index={index}>
            <td className="px-6 py-4">
                <span>
                    <div>
                        {moment
                            .utc(item.created_at)
                            .local()
                            .format('DD-MM-YYYY')}
                    </div>
                </span>
            </td>
            <td className="px-6 py-4">
                <span>{item.code ? item.code : '-'}</span>
            </td>
            <td className="px-6 py-4">
                <span>
                    <div>{item.creator.name}</div>
                    <div className="text-gray-400">{item.creator.email}</div>
                </span>
            </td>
            <td className="px-6 py-4">
                <span>
                    <div>{item.device}</div>
                    <div className="text-gray-400">{item.brand}</div>
                </span>
            </td>
            <td className="px-6 py-4">
                <span>
                    <div>
                        {protectionFilmsTypesQuery.data?.data.find(
                            ({ id }) =>
                                id ===
                                (item.protection_film_type_id ?? item.type)
                        )?.name || ''}
                    </div>
                    <div className="text-gray-400">
                        {t(`protection_films_sizes.${item.size}`)}
                    </div>
                </span>
            </td>
            <td className="px-6 py-4">
                <span>{item.pattern}</span>
            </td>
            <td className="px-6 py-4">
                <div className="flex items-center">
                    {item.correctly_cut ? (
                        <span className="bg-green-100 w-4 h-4 rounded-[10px]">
                            <CheckIcon className="w-3 h-3 m-[2px] text-green-600" />
                        </span>
                    ) : (
                        <span className="bg-red-100 w-4 h-4 rounded-[10px]">
                            <ExclamationCircleIcon className="w-3 h-3 m-[2px] text-red-600" />
                        </span>
                    )}
                    <div className="w-[1px] bg-gray-400 h-1 pr-[1px] mx-1"></div>
                    {item.correctly_applied ? (
                        <span className="bg-green-100 w-4 h-4 rounded-[10px]">
                            <CheckIcon className="w-3 h-3 m-[2px] text-green-600" />
                        </span>
                    ) : (
                        <span className="bg-red-100 w-4 h-4 rounded-[10px]">
                            <ExclamationCircleIcon className="w-3 h-3 m-[2px] text-red-600" />
                        </span>
                    )}
                    <span className="ml-1">
                        {t(`feedback_type.${item.feedback_type ?? 'success'}`)}
                    </span>
                </div>
                <div className="text-gray-400" ref={setTriggerRef}>
                    {(item.feedback?.length ?? 0) < 30
                        ? item.feedback
                        : `${item.feedback?.substring(0, 18)}...`}

                    {visible && item.feedback && (
                        <div
                            ref={setTooltipRef}
                            {...getTooltipProps({
                                className: classNames(
                                    'tooltip-container bg-gray-800 border border-gray-700',
                                    popperStyles.tooltip
                                ),
                            })}
                        >
                            <div
                                {...getArrowProps({
                                    className: classNames(
                                        'tooltip-arrow',
                                        popperStyles.arrow
                                    ),
                                })}
                            />
                            <span className="break-words inline-block max-w-60 text-white text-wrap">
                                {item.feedback}
                            </span>
                        </div>
                    )}
                </div>
            </td>
            <td className="px-6 py-4">
                <span>
                    <div>
                        <span>{item.city ? item.city : '-'}</span>
                    </div>
                    <div className="text-gray-400">
                        {item.region ? item.region : '-'},
                        {item.country ? item.country : '-'}
                    </div>
                </span>
            </td>
            <td className="px-6 py-4 text-right">
                <Menu3dots>
                    {({ open }) => (
                        <>
                            <Menu3dots.Button as={'span'}>
                                <Button3dots open={open} loading={false} />
                            </Menu3dots.Button>
                            <Menu3dots.Items>
                                <>
                                    <div className="divide-y border-t-[1px] border-gray-700 py-1">
                                        <Menu3dots.Item
                                            as="link"
                                            to={`/complaint-codes/${item.protection_film_id}`}
                                            state={{
                                                state: {
                                                    id: item.protection_film_id,
                                                },
                                            }}
                                        >
                                            <PresentationChartBarIcon className="w-5 h-5 mr-3" />
                                            {t(
                                                'list.used_complaint.statistics'
                                            )}
                                        </Menu3dots.Item>
                                    </div>
                                </>
                            </Menu3dots.Items>
                        </>
                    )}
                </Menu3dots>
            </td>
        </Tr>
    )
}

export default UsedComplaintCodesList
