import { useNavigate } from 'react-router-dom'
import { TabPanel } from '@headlessui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { QUERY_CACHE_USERS_KEY } from '../constants/index'
import { useReportTypesQuery, useUpdateUser } from '../api'
import { UpdateUserRequest } from '../api/types'
import { useFlashMessage, useSupervisorsDropdown } from '../hooks'
import { usePayloadFromUserForm, useUserFormFromProfile } from '../hooks/form'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { usePolicy } from '../hooks/usePolicy'
import { useActivePlotterForm } from '../hooks/forms/usePlotterActiveForm'
import { LANGUAGES } from '../constants/languages'

import type { UserForm, FormSubmitFn, RecipientForm } from '../types'

import PageLayout from '../components/PageLayout'
import User from '../components/User'
import UserTabView from '../components/UserTabView'
import UserFeatures from '../components/UserFeatures'
import SupervisorUsers from '../components/SupervisorUsers'
import UserReportSettings from 'components/UserReportSettings'
import { Loader } from 'components/ui'
import { useDeleteRecipientForm } from 'hooks/forms/useDeleteRecipientForm'
import ConfirmModal from 'components/ConfirmModal'
import { useMemo, useState } from 'react'
import { useRecipientForm } from 'hooks/forms/UseRecipientForm'
import RecipientModal from 'components/RecipientModal'
import UserEmployees from 'components/UserEmployees'

export default function ProfileView() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const navigate = useNavigate()
    const updateUserQuery = useUpdateUser({
        onSuccess: async () => {},
    })
    const activePlotterForm = useActivePlotterForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_USERS_KEY, userData?.id])
    })

    const userData = useCurrentUser().data?.data
    const policy = usePolicy()

    const userDropdownValues = useSupervisorsDropdown()
    const reportTypesQuery = useReportTypesQuery()
    const updateRecipientForm = useRecipientForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_USERS_KEY, userData?.id])
    })

    const initialValues: UserForm = useUserFormFromProfile(userData)

    const payloadFromUserForm = usePayloadFromUserForm<UpdateUserRequest>()
    const handleSubmit: FormSubmitFn<UserForm> = (values, formikHelpers) => {
        updateUserQuery.mutate(
            {
                id: String(userData?.id!),
                data: payloadFromUserForm(values),
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.user.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    navigate('/user')
                    await Promise.all([
                        queryClient.invalidateQueries(['profile']),
                        queryClient.invalidateQueries([
                            QUERY_CACHE_USERS_KEY,
                            'index',
                        ]),
                    ])
                },
                onError: (error, variables) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)

                    const recipientError = Object.keys(error.errors).find(
                        (key) => key.match('recipients.add')
                    )
                    if (!!recipientError) {
                        const index = parseInt(
                            recipientError.replace(/^\D+/g, '')
                        )
                        flashMessage({
                            type: 'danger',
                            content: error.errors[recipientError].replace(
                                recipientError,
                                variables.data.recipients?.add[index].email ??
                                    ''
                            ),
                        })
                    }
                },
            }
        )
    }

    const deleteRecipientForm = useDeleteRecipientForm()

    const lastRecipientId = useMemo(
        () =>
            1 +
            (userData?.recipients.reduce(
                (acc, { id }) => (id > acc ? id : acc),
                0
            ) ?? 0),
        [userData?.recipients]
    )

    const [query, setQuery] = useState('')

    const title =
        userData?.first_name && userData?.last_name
            ? `${userData?.first_name} ${userData?.last_name}`
            : userData?.name

    if (reportTypesQuery.isLoading) {
        return <Loader />
    }

    if (reportTypesQuery.isError) {
        return (
            <div className="text-red-600">{reportTypesQuery.error.message}</div>
        )
    }

    if (!reportTypesQuery.isSuccess) {
        return null
    }

    return (
        <PageLayout
            searchBarAction={setQuery}
            title={title}
            breadCrumbs={[
                {
                    name: t('bread.user_list'),
                    url: '/user',
                },
                {
                    name: title || '',
                },
            ]}
        >
            <Formik<UserForm>
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, isSubmitting }) => {
                    const optimisticRecipient =
                        userData?.recipients.findIndex(
                            (recipient) =>
                                recipient.id ===
                                updateRecipientForm.modal.getState()!
                        ) === -1
                    const recipientData =
                        userData?.recipients.find(
                            (recipient) =>
                                recipient.id ===
                                updateRecipientForm.modal.getState()!
                        ) ??
                        values.optimistic_recipients.find(
                            (_, index) =>
                                lastRecipientId + index ===
                                updateRecipientForm.modal.getState()!
                        )

                    const onUpdateRecipientSubmit = (
                        formValues: RecipientForm
                    ) => {
                        if (optimisticRecipient) {
                            const index =
                                values.optimistic_recipients.findIndex(
                                    (_, index) =>
                                        lastRecipientId + index ===
                                        updateRecipientForm.modal.getState()!
                                )
                            setFieldValue(
                                'optimistic_recipients',
                                values.optimistic_recipients.map(
                                    (recipient, ind) =>
                                        ind === index ? formValues : recipient
                                )
                            )
                            updateRecipientForm.modal.closeModal()
                        } else {
                            updateRecipientForm.form.setValues(formValues)
                            updateRecipientForm.form.submitForm()
                        }
                    }

                    const onDeleteRecipientSubmit = () => {
                        const id = deleteRecipientForm.modal.getState()!
                        if (
                            userData?.recipients.findIndex(
                                (recipient) => recipient.id === id
                            ) !== -1
                        ) {
                            deleteRecipientForm.form.submitForm()
                        } else if (
                            values.optimistic_recipients.findIndex(
                                (_, index) => lastRecipientId + index === id
                            ) !== -1
                        ) {
                            setFieldValue(
                                'values.optimistic_recipients',
                                values.optimistic_recipients.splice(
                                    lastRecipientId - id,
                                    1
                                )
                            )
                            deleteRecipientForm.modal.closeModal()
                        }
                    }

                    return (
                        <>
                            <UserTabView
                                haveUsers={policy.isSupervisor()}
                                haveEmployees={policy.isUser()}
                                haveReports={
                                    policy.isUser() ||
                                    policy.isSupervisor() ||
                                    policy.isAdmin()
                                }
                            >
                                <TabPanel>
                                    <User
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        isSubmitting={isSubmitting}
                                        supervisors={userDropdownValues}
                                        languages={LANGUAGES}
                                        readonly={policy.isUser()}
                                        isProfile={true}
                                        plottersList={userData?.cutters || []}
                                        activePlotterForm={activePlotterForm}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <UserFeatures
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        isSubmitting={isSubmitting}
                                        readonly={true}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <SupervisorUsers
                                        id={String(userData?.id!)}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <UserReportSettings
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        isSubmitting={isSubmitting}
                                        reportTypes={reportTypesQuery.data.data}
                                        handleRemove={(id: number) => {
                                            deleteRecipientForm.modal.setState(
                                                id
                                            )
                                            deleteRecipientForm.modal.openModal()
                                        }}
                                        handleUpdate={(id: number) => {
                                            updateRecipientForm.modal.setState(
                                                id
                                            )
                                            updateRecipientForm.modal.openModal()
                                        }}
                                        recipients={userData?.recipients ?? []}
                                        lastRecipientId={lastRecipientId}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <UserEmployees
                                        id={String(userData?.id!)}
                                        query={query}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        isSubmitting={isSubmitting}
                                    />
                                </TabPanel>
                            </UserTabView>
                            <ConfirmModal
                                modal={deleteRecipientForm.modal}
                                title={t('delete.recipient.title')}
                                description={t('delete.recipient.description')}
                                actionLabel={t('action.delete')}
                                onSubmit={onDeleteRecipientSubmit}
                                isSubmitting={
                                    deleteRecipientForm.form.isSubmitting
                                }
                            />
                            <RecipientModal
                                modal={updateRecipientForm.modal}
                                recipientData={recipientData}
                                onSubmit={onUpdateRecipientSubmit}
                            />
                        </>
                    )
                }}
            </Formik>
        </PageLayout>
    )
}
