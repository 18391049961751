import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TabPanel } from '@headlessui/react'
import { Formik } from 'formik'
import { useQueryClient } from 'react-query'

import {
    QUERY_CACHE_PLOTTERS_KEY,
    QUERY_CACHE_USERS_KEY,
} from '../constants/index'
import { useReportTypesQuery, useUpdateUser, useUserByIdQuery } from '../api'
import {
    useFlashMessage,
    useModal,
    useSupervisorsDropdown,
    useUserRoles,
} from '../hooks'
import {
    usePayloadFromUserForm,
    useUserFormGetInitialValues,
} from '../hooks/form'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useActivePlotterForm } from '../hooks/forms/usePlotterActiveForm'
import { usePlotterChangeUserForm } from '../hooks/forms/usePlotterChangeUserForm'

import type {
    UserForm,
    FormSubmitFn,
    AssignPlotterForm,
    ReportForm,
    RecipientForm,
} from '../types'
import { UpdateUserRequest } from '../api/types'

import { Loader } from '../components/ui'
import PageLayout from '../components/PageLayout'
import User from '../components/User'
import UserTabView from '../components/UserTabView'
import UserFeatures from '../components/UserFeatures'
import { LANGUAGES } from '../constants/languages'
import SupervisorUsers from '../components/SupervisorUsers'
import SelectPlotterModal from '../components/SelectPlotterModal'
import UserReportSettings from 'components/UserReportSettings'
import { useTestReport } from 'api/endpoints/test'
import ReportModal from 'components/ReportModal'
import ConfirmModal from 'components/ConfirmModal'
import { useDeleteRecipientForm } from 'hooks/forms/useDeleteRecipientForm'
import { useRecipientForm } from 'hooks/forms/UseRecipientForm'
import RecipientModal from 'components/RecipientModal'
import UserEmployees from 'components/UserEmployees'
import { usePolicy } from 'hooks/usePolicy'

export default function EditUserView() {
    const { t } = useTranslation()

    const { id } = useParams()

    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const navigate = useNavigate()
    const updateUserQuery = useUpdateUser({
        onSuccess: async () => {},
    })
    const userQuery = useUserByIdQuery(id!, {
        enabled: !!id,
    })
    const userData = userQuery.data?.data
    const profileData = useCurrentUser()
    const userRoles = useUserRoles()

    const userDropdownValues = useSupervisorsDropdown()
    const reportTypesQuery = useReportTypesQuery()
    const plotterModal = useModal()
    const reportModal = useModal()
    const onDemandReport = useTestReport({
        onSuccess: async () => {},
    })
    const handleOnDemandReport: FormSubmitFn<ReportForm> = (
        values,
        formikHelpers
    ) => {
        onDemandReport.mutate(
            {
                email: values.email,
                user: parseInt(id!),
                report_type_id:
                    typeof values.report_type?.id === 'number'
                        ? values.report_type?.id
                        : undefined,
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.report.send'),
                    })
                    formikHelpers.setSubmitting(false)
                    reportModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const initialValues: UserForm = useUserFormGetInitialValues(id!)

    const activePlotterForm = useActivePlotterForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_USERS_KEY, id])
    })
    const changeUserPlotterForm = usePlotterChangeUserForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_USERS_KEY, id])
        queryClient.invalidateQueries([QUERY_CACHE_PLOTTERS_KEY, 'index'])
    })
    const deleteRecipientForm = useDeleteRecipientForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_USERS_KEY, id])
    })
    const updateRecipientForm = useRecipientForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_USERS_KEY, id])
    })

    const payloadFromUserForm = usePayloadFromUserForm<UpdateUserRequest>()
    const handleSubmit: FormSubmitFn<UserForm> = (values, formikHelpers) => {
        updateUserQuery.mutate(
            {
                id: id!,
                data: payloadFromUserForm(values),
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.user.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    navigate('/user')
                    queryClient.removeQueries([QUERY_CACHE_USERS_KEY])
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_USERS_KEY,
                        'index',
                    ])
                    if (String(profileData.data?.data.id) === id) {
                        await queryClient.invalidateQueries(['profile'])
                    }
                },
                onError: (error, variables) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)

                    const recipientError = Object.keys(error.errors).find(
                        (key) => key.match('recipients.add')
                    )
                    if (!!recipientError) {
                        const index = parseInt(
                            recipientError.replace(/^\D+/g, '')
                        )
                        flashMessage({
                            type: 'danger',
                            content: error.errors[recipientError].replace(
                                recipientError,
                                variables.data.recipients?.add[index].email ??
                                    ''
                            ),
                        })
                    }
                },
            }
        )
    }

    const handleSubmitPlotter: FormSubmitFn<AssignPlotterForm> = (
        values,
        formikHelpers
    ) => {
        updateUserQuery.mutate(
            {
                id: id!,
                data: {
                    cutters: {
                        attach: values.attach.map((val) => ({ id: val.id })),
                    },
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.user.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_USERS_KEY,
                        id,
                    ])
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PLOTTERS_KEY,
                        'index',
                    ])
                    plotterModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const lastRecipientId = useMemo(
        () =>
            1 +
            (userData?.recipients.reduce(
                (acc, { id }) => (id > acc ? id : acc),
                0
            ) ?? 0),
        [userData?.recipients]
    )

    const [query, setQuery] = useState('')
    const policy = usePolicy()

    if (userQuery.isLoading || reportTypesQuery.isLoading) {
        return <Loader />
    }

    if (reportTypesQuery.isError) {
        return (
            <div className="text-red-600">{reportTypesQuery.error.message}</div>
        )
    }

    if (userQuery.isError) {
        return <div className="text-red-600">{userQuery.error.message}</div>
    }

    if (!userQuery.isSuccess || !reportTypesQuery.isSuccess) {
        return null
    }

    const title =
        userData?.first_name && userData?.last_name
            ? `${userData?.first_name} ${userData?.last_name}`
            : userData?.name

    return (
        <PageLayout
            title={title}
            searchBarAction={setQuery}
            breadCrumbs={[
                {
                    name: t('bread.user_list'),
                    url: '/user',
                },
                {
                    name: title || '',
                },
            ]}
        >
            <>
                <Formik<UserForm>
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, isSubmitting }) => {
                        const optimisticRecipient =
                            userData?.recipients.findIndex(
                                (recipient) =>
                                    recipient.id ===
                                    updateRecipientForm.modal.getState()!
                            ) === -1
                        const recipientData =
                            userData?.recipients.find(
                                (recipient) =>
                                    recipient.id ===
                                    updateRecipientForm.modal.getState()!
                            ) ??
                            values.optimistic_recipients.find(
                                (_, index) =>
                                    lastRecipientId + index ===
                                    updateRecipientForm.modal.getState()!
                            )

                        const onUpdateRecipientSubmit = (
                            formValues: RecipientForm
                        ) => {
                            if (optimisticRecipient) {
                                const index =
                                    values.optimistic_recipients.findIndex(
                                        (_, index) =>
                                            lastRecipientId + index ===
                                            updateRecipientForm.modal.getState()!
                                    )

                                if (index !== -1) {
                                    const newOptimisticRecipients = [
                                        ...values.optimistic_recipients,
                                    ]
                                    newOptimisticRecipients[index] = formValues

                                    setFieldValue(
                                        'optimistic_recipients',
                                        newOptimisticRecipients
                                    )
                                }

                                updateRecipientForm.modal.closeModal()
                            } else {
                                updateRecipientForm.form.setValues(formValues)
                                updateRecipientForm.form.submitForm()
                            }
                        }

                        const onDeleteRecipientSubmit = () => {
                            const id = deleteRecipientForm.modal.getState()!
                            if (
                                userData?.recipients.findIndex(
                                    (recipient) => recipient.id === id
                                ) !== -1
                            ) {
                                deleteRecipientForm.form.submitForm()
                            } else if (
                                values.optimistic_recipients.findIndex(
                                    (_, index) => lastRecipientId + index === id
                                ) !== -1
                            ) {
                                const optimisticIndex =
                                    values.optimistic_recipients.findIndex(
                                        (_, index) =>
                                            lastRecipientId + index === id
                                    )

                                if (optimisticIndex !== -1) {
                                    const newOptimisticRecipients = [
                                        ...values.optimistic_recipients,
                                    ]
                                    newOptimisticRecipients.splice(
                                        optimisticIndex,
                                        1
                                    )

                                    setFieldValue(
                                        'optimistic_recipients',
                                        newOptimisticRecipients
                                    )
                                }

                                deleteRecipientForm.modal.closeModal()
                            }
                        }

                        return (
                            <>
                                <UserTabView
                                    onAction={
                                        (policy.isAdmin() ||
                                            policy.isSupervisor()) &&
                                        userData?.role?.id === userRoles.user.id
                                            ? plotterModal.openModal
                                            : undefined
                                    }
                                    onSecondaryAction={reportModal.openModal}
                                    haveUsers={
                                        userData?.role?.id ===
                                        userRoles.supervisor.id
                                    }
                                    haveEmployees={
                                        userData?.role?.id === userRoles.user.id
                                    }
                                    haveReports={
                                        userData?.role?.id ===
                                            userRoles.user.id ||
                                        userData?.role?.id ===
                                            userRoles.supervisor.id ||
                                        userData?.role?.id ===
                                            userRoles.admin.id
                                    }
                                >
                                    <TabPanel>
                                        <User
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isSubmitting={isSubmitting}
                                            supervisors={userDropdownValues}
                                            languages={LANGUAGES}
                                            readonly={
                                                !policy.isAdmin() &&
                                                !policy.isSupervisor()
                                            }
                                            plottersList={
                                                userData?.cutters || []
                                            }
                                            activePlotterForm={
                                                activePlotterForm
                                            }
                                            changeUserPlotterForm={
                                                changeUserPlotterForm
                                            }
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <UserFeatures
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            readonly={
                                                !policy.isAdmin() &&
                                                !policy.isSupervisor()
                                            }
                                            isSubmitting={isSubmitting}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <SupervisorUsers id={id!} />
                                    </TabPanel>
                                    <TabPanel>
                                        <UserReportSettings
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            readonly={
                                                !policy.isAdmin() &&
                                                !policy.isSupervisor()
                                            }
                                            isSubmitting={isSubmitting}
                                            reportTypes={
                                                reportTypesQuery.data.data
                                            }
                                            handleRemove={(id: number) => {
                                                deleteRecipientForm.modal.setState(
                                                    id
                                                )
                                                deleteRecipientForm.modal.openModal()
                                            }}
                                            handleUpdate={(id: number) => {
                                                updateRecipientForm.modal.setState(
                                                    id
                                                )
                                                updateRecipientForm.modal.openModal()
                                            }}
                                            recipients={
                                                userData?.recipients ?? []
                                            }
                                            lastRecipientId={lastRecipientId}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <UserEmployees
                                            id={id!}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            readonly={
                                                !policy.isAdmin() &&
                                                !policy.isSupervisor()
                                            }
                                            isSubmitting={isSubmitting}
                                            query={query}
                                        />
                                    </TabPanel>
                                </UserTabView>
                                <ConfirmModal
                                    modal={deleteRecipientForm.modal}
                                    title={t('delete.recipient.title')}
                                    description={t(
                                        'delete.recipient.description'
                                    )}
                                    actionLabel={t('action.delete')}
                                    onSubmit={onDeleteRecipientSubmit}
                                    isSubmitting={
                                        deleteRecipientForm.form.isSubmitting
                                    }
                                />
                                <RecipientModal
                                    modal={updateRecipientForm.modal}
                                    recipientData={recipientData}
                                    onSubmit={onUpdateRecipientSubmit}
                                />
                            </>
                        )
                    }}
                </Formik>
                <SelectPlotterModal
                    modal={plotterModal}
                    onSubmit={handleSubmitPlotter}
                />
                <ReportModal
                    modal={reportModal}
                    onSubmit={handleOnDemandReport}
                    reportTypes={reportTypesQuery.data.data}
                />
            </>
        </PageLayout>
    )
}
