import { useQuery, useMutation } from 'react-query'
import qs from 'qs'

import client from '../client'
import { QUERY_CACHE_BLADE_TYPES_KEY } from '../../constants/index'

import type {
    UseQueryOptions,
    UseQueryResult,
    UseMutationOptions,
} from 'react-query'
import type {
    ResponseData,
    ResponseList,
    ResponseError,
    BladeTypeResponse,
    BladeTypeListResponse,
    DeleteVariables,
    UpdateBladeTypeVariables,
    CreateBladeTypeVariables,
} from '../types'

export const getBladeType = <T>(filters?: {}): Promise<T> =>
    client.get(
        '/blade-types' + (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const useBladeTypeQuery = <T = ResponseList<BladeTypeListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_BLADE_TYPES_KEY, 'index', filters],
        () => getBladeType<T>(filters),
        options
    )

export const getBladeTypeById = (
    id: string
): Promise<ResponseData<BladeTypeListResponse>> =>
    client.get(`/blade-types/${id}`)

export const useGetBladeTypeByIdQuery = <
    TData = ResponseData<BladeTypeListResponse>,
>(
    id: string,
    queryOptions?: UseQueryOptions<
        ResponseData<BladeTypeListResponse>,
        ResponseError,
        TData,
        [string, string]
    >
): UseQueryResult<TData, ResponseError> =>
    useQuery(
        [QUERY_CACHE_BLADE_TYPES_KEY, id],
        () => getBladeTypeById(id),
        queryOptions
    )

const createBladeType = ({
    data,
}: CreateBladeTypeVariables): Promise<ResponseData<BladeTypeResponse>> =>
    client.post('/blade-types', data)

export const useCreateBladeType = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<BladeTypeResponse>,
            ResponseError,
            CreateBladeTypeVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => createBladeType(data), options)

const updateBladeType = ({
    id,
    data,
}: UpdateBladeTypeVariables): Promise<ResponseData<BladeTypeResponse>> =>
    client.patch(`/blade-types/${id}`, data)

export const useUpdateBladeType = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<BladeTypeResponse>,
            ResponseError,
            UpdateBladeTypeVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => updateBladeType(data), options)

const deleteBladeType = ({
    id,
}: DeleteVariables): Promise<ResponseData<void>> =>
    client.delete(`/blade-types/${id}`)

export const useDeleteBladeType = (
    options?: Omit<
        UseMutationOptions<ResponseData<void>, ResponseError, DeleteVariables>,
        'mutationFn'
    >
) => useMutation((data) => deleteBladeType(data), options)
