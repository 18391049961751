import React from 'react'
import { useTranslation } from 'react-i18next'
import PageLayout from '../components/PageLayout'

export default function NotFoundView() {
    const { t } = useTranslation()

    return (
        <PageLayout title={t('not_found.title')}>
            <div>{t('not_found.description')}</div>
        </PageLayout>
    )
}
