import React from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import { getCxFromStyles } from '../helpers'
import Modal from './Modal'
import { Button, Label } from './ui'
import { TextField } from './forms'

import type { FormSubmitFn, IModal, BladeTypeForm } from '../types'

import styles from './Modal.module.scss'
import { BladeTypeResponse, ProtectionFilmTypeListResponse } from '../api/types'

export type BladeTypeModalProps = {
    modal: IModal
    bladeTypeData?: BladeTypeResponse
    onSubmit: FormSubmitFn<BladeTypeForm>
    filmTypes: ProtectionFilmTypeListResponse
}

export default function BladeTypeModal({
    modal,
    bladeTypeData,
    onSubmit,
    filmTypes,
}: BladeTypeModalProps) {
    const cx = getCxFromStyles(styles)
    const { t } = useTranslation()

    const initialValues: BladeTypeForm = bladeTypeData
        ? {
              name: bladeTypeData.name,
              filmTypes: Object.fromEntries(
                  bladeTypeData.protection_film_types.map(
                      ({ protection_film_type_id, cuts_number }) => {
                          return [protection_film_type_id, cuts_number]
                      }
                  )
              ),
          }
        : {
              name: '',
              filmTypes: {},
          }

    return (
        <Modal modal={modal} type="default">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg pb-6 leading-6 font-medium text-white text-center"
                >
                    {bladeTypeData
                        ? t('form.blade_type.edit_title')
                        : t('form.blade_type.title')}
                </DialogTitle>
                <Formik<BladeTypeForm>
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                >
                    {({ isSubmitting, values, setFieldValue, errors }) => (
                        <Form className="px-2 pb-2">
                            <div className="mr-auto max-w-3xl grid grid-cols-1 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.blade_type.field.name')}
                                    </Label>
                                    <TextField name="name" />
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="inline-block text-lg leading-8 font-medium">
                                    {t('form.blade_type.max_cuts.title')}
                                </span>
                                <span className="pb-5 text-sm leading-5 font-normal">
                                    {t('form.blade_type.max_cuts.description')}
                                </span>
                            </div>
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                {filmTypes.map((filmType, index) => {
                                    const err = Object.entries(errors).find(
                                        ([key, v]) => key.includes(`.${index}.`)
                                    )?.[1]
                                    return (
                                        <div className="col-span-1">
                                            <Label>{filmType.name}</Label>
                                            <TextField
                                                name={`protection_film_type_${filmType.id}`}
                                                value={
                                                    values.filmTypes[
                                                        filmType.id
                                                    ]
                                                }
                                                onChange={(e) =>
                                                    setFieldValue('filmTypes', {
                                                        ...values.filmTypes,
                                                        [filmType.id]:
                                                            e.target.value,
                                                    })
                                                }
                                                error={
                                                    typeof err === 'string'
                                                        ? err
                                                        : undefined
                                                }
                                            />
                                        </div>
                                    )
                                })}
                            </div>

                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    {t('form.save')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
