import { useUserByIdQuery } from '../api'
import { useUserRoles, useUserStatuses } from '.'
import type { UserForm, DropdownItem } from '../types'
import { LANGUAGES } from '../constants/languages'
import { UpdateUserRequest, UserResponse } from '../api/types'
import { usePolicy } from './usePolicy'
import { useCurrentUser } from './useCurrentUser'

export function useUserInitialValues(): UserForm {
    const userStatuses = useUserStatuses()
    const userRoles = useUserRoles()
    const policy = usePolicy()
    const currentUser = useCurrentUser()

    return {
        name: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        status: userStatuses[0],
        role: userRoles.user.id,
        supervisor: policy.isSupervisor()
            ? {
                  id: currentUser.data?.data.id,
                  name: currentUser.data?.data.name,
              }
            : undefined,
        lang: undefined,
        country: undefined,
        city: undefined,
        region: '',
        email_report: '',
        reports: [],
        optimistic_recipients: [],
        recipients: [],
        employees_auth_required: false,
    } as UserForm
}

export function useUserFormGetInitialValues(id: string): UserForm {
    const userStatuses = useUserStatuses()
    const userRoles = useUserRoles()

    const userQuery = useUserByIdQuery(id!, {
        enabled: !!id,
    })
    const userData = userQuery.data?.data

    return userFormFromUser(userData, userStatuses, userRoles.list)
}

export function useUserFormFromProfile(
    userData: UserResponse | undefined
): UserForm {
    const userStatuses = useUserStatuses()
    const userRoles = useUserRoles()

    return userFormFromUser(userData, userStatuses, userRoles.list)
}

function userFormFromUser(
    userData: UserResponse | undefined,
    userStatuses: DropdownItem[],
    userRoles: DropdownItem[]
): UserForm {
    return {
        name: userData?.name ?? '',
        first_name: userData?.first_name ?? '',
        last_name: userData?.last_name ?? '',
        phone: userData?.phone ?? '',
        email: userData?.email ?? '',
        status: userData?.active ? userStatuses[0] : userStatuses[1],
        supervisor: userData?.superior ?? undefined,
        lang: LANGUAGES.find((lng) => lng.id === userData?.lang),
        role: userRoles.find((role) => role.id === userData?.role?.id)?.id,
        country: userData?.attributes?.country ?? undefined,
        city: userData?.attributes?.city ?? undefined,
        region: userData?.attributes?.region ?? '',
        reports: userData?.reports?.map((rep) => rep.id) ?? [],
        optimistic_recipients: [],
        recipients: userData?.recipients ?? [],
        email_report: '',
        employees_auth_required: userData?.employees_auth_required ?? false,
    }
}

export function usePayloadFromUserForm<T extends UpdateUserRequest>(): (
    values: UserForm
) => T {
    const userRoles = useUserRoles()

    return (values: UserForm) => payloadFromUserForm<T>(values, userRoles)
}

function payloadFromUserForm<T extends UpdateUserRequest>(
    values: UserForm,
    userRoles: any
): T {
    return {
        name: values.name,
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
        email: values.email,
        active: values.status?.id === 'active',
        lang: values.lang?.id,
        roles: values.role ? [{ id: values.role }] : undefined,
        superior:
            values.role === userRoles.user.id ? values.supervisor?.id : null,
        attributes:
            values.country || values.city || values.region
                ? {
                      country: values.country?.id
                          ? String(values.country?.id)
                          : null,
                      city: values.city?.id ? String(values.city?.id) : null,
                      region: values.region,
                  }
                : null,
        reports: values.reports.map((rep) => ({ id: rep })),
        recipients: {
            add: values.optimistic_recipients,
        },
        employees_auth_required: values.employees_auth_required,
    } as T
}
