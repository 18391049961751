import React from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import { getCxFromStyles } from '../helpers'
import Modal from './Modal'
import { Button, Label } from './ui'
import { TextField } from './forms'

import type { FormSubmitFn, IModal, EmployeeForm } from '../types'

import styles from './Modal.module.scss'
import { EmployeeResponse } from '../api/types'

export type EmployeeModalProps = {
    modal: IModal
    employeeData?: EmployeeResponse
    onSubmit: FormSubmitFn<EmployeeForm>
}

export default function EmployeeModal({
    modal,
    employeeData,
    onSubmit,
}: EmployeeModalProps) {
    const cx = getCxFromStyles(styles)
    const { t } = useTranslation()

    const initialValues: EmployeeForm = employeeData
        ? {
              name: employeeData.name,
              email: employeeData.email,
              active: employeeData.active,
          }
        : {
              name: '',
              email: '',
              active: true,
          }

    return (
        <Modal modal={modal} type="default">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg pb-6 leading-6 font-medium text-white text-center"
                >
                    {employeeData
                        ? t('form.employee.edit_title')
                        : t('form.employee.title')}
                </DialogTitle>
                <Formik<EmployeeForm>
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className="px-2 pb-2">
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.employee.field.name')}
                                    </Label>
                                    <TextField name="name" />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.employee.field.email')}
                                    </Label>
                                    <TextField name="email" />
                                </div>
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    {t('form.save')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
