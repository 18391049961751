import { useMemo } from 'react'
import { usePlotterTypeQuery } from 'api'
import { DropdownItem } from 'types'

export const usePlotterTypes = (): DropdownItem[] => {
    const typesQuery = usePlotterTypeQuery()

    const plotterTypes = useMemo(
        () =>
            !!typesQuery.data
                ? typesQuery.data.data.map(({ id, name }) => ({ id, name }))
                : [],
        [typesQuery.data]
    )

    return plotterTypes
}
