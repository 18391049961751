import { useTranslation } from 'react-i18next'
import { useRolesQuery } from '../api/endpoints/roles'
import { useMemo } from 'react'

const DEFAULT_TRAINER_ROLE_ID = 6 // TODO: REPLACE WITH REAL VALUES
const DEFAULT_SERVICEMAN_ROLE_ID = 5
const DEFAULT_EDITOR_ROLE_ID = 4
const DEFAULT_USER_ROLE_ID = 3
const DEFAULT_SUPERVISOR_ROLE_ID = 2
const DEFAULT_ADMIN_ROLE_ID = 1

const ADMIN_VALUE = 'admin'
const SUPERIOR_VALUE = 'superior'
const TRADER_VALUE = 'trader'
const SERVICEMAN_VALUE = 'serviceman'
const TRAINER_VALUE = 'trainer'
const EDITOR_VALUE = 'editor'

export const useUserRoles = () => {
    const { t } = useTranslation()

    const rolesQuery = useRolesQuery()

    const user = useMemo(
        () => ({
            id:
                rolesQuery.data?.data.find(({ name }) => name === TRADER_VALUE)
                    ?.id || DEFAULT_USER_ROLE_ID,
            name: t('dictionary.user'),
            short_name: t('dictionary.user_short'),
            description: t('dictionary.user_description'),
        }),
        [t, rolesQuery.data]
    )

    const supervisor = useMemo(
        () => ({
            id:
                rolesQuery.data?.data.find(
                    ({ name }) => name === SUPERIOR_VALUE
                )?.id || DEFAULT_SUPERVISOR_ROLE_ID,
            name: t('dictionary.supervisor'),
            short_name: t('dictionary.supervisor_short'),
            description: t('dictionary.supervisor_description'),
        }),
        [t, rolesQuery.data]
    )

    const admin = useMemo(
        () => ({
            id:
                rolesQuery.data?.data.find(({ name }) => name === ADMIN_VALUE)
                    ?.id || DEFAULT_ADMIN_ROLE_ID,
            name: t('dictionary.admin'),
            short_name: t('dictionary.admin_short'),
            description: t('dictionary.admin_description'),
        }),
        [t, rolesQuery.data]
    )

    const serviceman = useMemo(
        () => ({
            id:
                rolesQuery.data?.data.find(
                    ({ name }) => name === SERVICEMAN_VALUE
                )?.id || DEFAULT_SERVICEMAN_ROLE_ID,
            name: t('dictionary.serviceman'),
            short_name: t('dictionary.serviceman_short'),
            description: t('dictionary.serviceman_description'),
        }),
        [t, rolesQuery.data]
    )

    const trainer = useMemo(
        () => ({
            id:
                rolesQuery.data?.data.find(({ name }) => name === TRAINER_VALUE)
                    ?.id || DEFAULT_TRAINER_ROLE_ID,
            name: t('dictionary.trainer'),
            short_name: t('dictionary.trainer_short'),
            description: t('dictionary.trainer_description'),
        }),
        [t, rolesQuery.data]
    )

    const editor = useMemo(
        () => ({
            id:
                rolesQuery.data?.data.find(({ name }) => name === EDITOR_VALUE)
                    ?.id || DEFAULT_EDITOR_ROLE_ID,
            name: t('dictionary.editor'),
            short_name: t('dictionary.editor_short'),
            description: t('dictionary.editor_description'),
        }),
        [t, rolesQuery.data]
    )

    return useMemo(
        () => ({
            list: [user, supervisor, editor, serviceman, trainer, admin],
            user,
            supervisor,
            admin,
            editor,
            serviceman,
            trainer,
        }),
        [user, supervisor, editor, serviceman, trainer, admin]
    )
}
