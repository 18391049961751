import { useQuery, useMutation } from 'react-query'

import client from '../client'
import { QUERY_CACHE_COMPLAINTS_KEY } from '../../constants/index'

import type {
    UseQueryOptions,
    UseQueryResult,
    UseMutationOptions,
} from 'react-query'
import type {
    ResponseData,
    ResponseError,
    ComplaintResponse,
    CreateComplaintVariables,
    ResponseList,
    ComplaintListResponse,
    DeleteVariables,
    UsedComplaintListResponse,
} from '../types'
import qs from 'qs'

export const getComplaintById = (
    id: string
): Promise<ResponseData<ComplaintResponse>> =>
    client.get(`/service-codes/${id}`)

export const useComplaintByIdQuery = <TData = ResponseData<ComplaintResponse>>(
    id: string,
    queryOptions?: UseQueryOptions<
        ResponseData<ComplaintResponse>,
        ResponseError,
        TData,
        [string, string]
    >
): UseQueryResult<TData, ResponseError> =>
    useQuery(
        [QUERY_CACHE_COMPLAINTS_KEY, id],
        () => getComplaintById(id),
        queryOptions
    )

export const getComplaints = <T>(filters?: {}): Promise<T> =>
    client.get(
        '/service-codes' + (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const useComplaintsQuery = <T = ResponseList<ComplaintListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_COMPLAINTS_KEY, 'index', filters],
        () => getComplaints<T>({ ...filters }),
        options
    )

export const getUsedComplaints = <T>(filters?: {}): Promise<T> =>
    client.get(
        '/service-codes/used' + (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const useUsedComplaintsQuery = <
    T = ResponseList<UsedComplaintListResponse>,
>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_COMPLAINTS_KEY, 'index', filters],
        () => getUsedComplaints<T>({ ...filters }),
        options
    )

const createComplaint = ({
    data,
}: CreateComplaintVariables): Promise<ResponseData<ComplaintResponse>> =>
    client.post('/service-codes/generate', data)

export const useCreateComplaint = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<ComplaintResponse>,
            ResponseError,
            CreateComplaintVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => createComplaint(data), options)

const deleteComplaint = ({
    id,
}: DeleteVariables): Promise<ResponseData<void>> =>
    client.delete(`/service-codes/${id}`)

export const useDeleteComplaint = (
    options?: Omit<
        UseMutationOptions<ResponseData<void>, ResponseError, DeleteVariables>,
        'mutationFn'
    >
) => useMutation((data) => deleteComplaint(data), options)
