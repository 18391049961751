import React from 'react'
import { useFormikContext } from 'formik'

import styles from './ErrorMessage.module.scss'

const ErrorMessage: React.FC<{ name: string; error?: string }> = ({
    name,
    error,
}) => {
    const { errors } = useFormikContext() as { errors: Record<string, string> }

    if (!error && (!errors || !(name in errors))) {
        return null
    }

    return <div className={styles.root}>{error || errors[name]}</div>
}

export default ErrorMessage
