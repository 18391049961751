import React from 'react'
import { useTranslation } from 'react-i18next'

export default function NotFoundNotLoggedView() {
    const { t } = useTranslation()

    return (
        <div className="flex flex-col items-center gap-12 w-full max-w-[296px] lg:max-w-[576px]">
            <p className="text-sm leading-5 font-medium text-gray-400">
                {t('not_found.title')}
            </p>
            <div className="flex flex-col gap-4 w-full max-w-[296px] lg:max-w-[576px]">
                <div>{t('not_found.description')}</div>
            </div>
        </div>
    )
}
