import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Spinner, Toggle } from './ui'
import TableFooter from './TableFooter'
import { Button3dots } from './ui'
import Menu3dots from './Menu3dots'
import {
    TableHeader,
    TableContainer,
    Table,
    Tbody,
    Tempty,
    Tr,
} from './ui/List'

import { PencilAltIcon } from '@heroicons/react/solid'
import { TrashIcon } from '@heroicons/react/outline'

import type { UseQueryResult } from 'react-query'
import type {
    PlotterTypeVideoListFilterState,
    PlotterTypeVideoListFilterAction,
} from '../types'
import type {
    ResponseList,
    PlotterTypeVideoListResponse,
    PlotterTypeVideoResponse,
} from '../api/types'
import SortButton from './ui/SortButton'
import moment from 'moment'

type PlotterTypeVideoListProps = {
    plotterTypeVideoQuery: UseQueryResult<
        ResponseList<PlotterTypeVideoListResponse>
    >
    filters: PlotterTypeVideoListFilterState
    filterCount: number
    filterAction: React.Dispatch<PlotterTypeVideoListFilterAction>
    handleRemove?: (id: number) => void
    handleEdit?: (id: number, payload: PlotterTypeVideoResponse) => void
    handleToggle: (id: number, state: boolean) => void
}

type TableProps = {
    data: PlotterTypeVideoListResponse
    handleRemove?: (id: number) => void
    handleEdit?: (id: number, payload: PlotterTypeVideoResponse) => void
    handleToggle: (id: number, state: boolean) => void
}

const PlotterTypeVideoList: React.FC<PlotterTypeVideoListProps> = ({
    plotterTypeVideoQuery,
    handleToggle,
    filters,
    filterCount,
    filterAction,
    handleRemove,
    handleEdit,
}) => {
    const { t } = useTranslation()

    return (
        <TableContainer>
            <Table>
                <TableHeader>
                    <th scope="col" className="w-[95px] px-6 py-4">
                        <SortButton
                            disabled={plotterTypeVideoQuery.isLoading}
                            filters={filters}
                            action={filterAction}
                            name="id"
                        >
                            <div className="text-left tracking-wider uppercase">
                                {t('list.plotter_type_video.id')}
                            </div>
                        </SortButton>
                    </th>
                    <th
                        scope="col"
                        className="w-[95px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.plotter_type_video.placeholder')}
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        <div className="text-left tracking-wider uppercase">
                            {t('list.plotter_type_video.name')}
                        </div>
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[550px] px-6 py-4 text-left tracking-wider"
                    >
                        <div className="text-left tracking-wider uppercase">
                            {t('list.plotter_type_video.link')}
                        </div>
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[100px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        {t('list.plotter_type_video.active')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[100px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        {t('list.plotter_type_video.created')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[65px] px-6 py-4 text-left tracking-wider"
                    ></th>
                </TableHeader>
                {plotterTypeVideoQuery.isSuccess &&
                    plotterTypeVideoQuery.isFetched && (
                        <>
                            {plotterTypeVideoQuery.data.meta.total > 0 && (
                                <TableBody
                                    data={plotterTypeVideoQuery.data.data}
                                    handleToggle={handleToggle}
                                    handleRemove={handleRemove}
                                    handleEdit={handleEdit}
                                />
                            )}
                        </>
                    )}
                {plotterTypeVideoQuery.data?.meta.total === 0 && (
                    <Tbody>
                        <Tr key={-1} index={0}>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4 relative h-12">
                                {filterCount === 0 && (
                                    <Tempty>
                                        {t(
                                            'list.plotter_type_video.empty_list'
                                        )}
                                    </Tempty>
                                )}
                                {filterCount > 0 && (
                                    <Tempty>
                                        {t(
                                            'list.plotter_type_video.empty_search'
                                        )}
                                    </Tempty>
                                )}
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                        </Tr>
                    </Tbody>
                )}
            </Table>
            {plotterTypeVideoQuery.isLoading && (
                <div className="divide-y divide-gray-700 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (_, index) => (
                            <div
                                key={index}
                                className={classNames('relative h-[68px]', {
                                    'bg-gray-800': index % 2,
                                    'bg-background': !(index % 2),
                                })}
                            >
                                {index === 4 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            {filterAction && (
                <TableFooter
                    meta={plotterTypeVideoQuery.data?.meta}
                    filterAction={filterAction}
                />
            )}
        </TableContainer>
    )
}

const TableBody: React.FC<TableProps> = ({
    data,
    handleRemove,
    handleToggle,
    handleEdit,
}) => {
    const { t } = useTranslation()

    const isMutating = false
    return (
        <Tbody>
            {data.map((item, index) => (
                <Tr key={item.id} index={index}>
                    <td className="px-6 py-4">
                        <span>{item.id}</span>
                    </td>
                    <td className="px-6 py-4">
                        <span className="block w-12 h-12 relative">
                            {item.cover_url ? (
                                <img
                                    className="max-h-full m-auto absolute top-0 bottom-0 left-0 right-0"
                                    alt={item.name}
                                    src={item.cover_url}
                                />
                            ) : (
                                <span />
                            )}
                        </span>
                    </td>
                    <td className="px-6 py-4">{item.name}</td>
                    <td className="px-6 py-4">{item.link}</td>
                    <td className="px-6 py-4">
                        <Toggle
                            checked={item.active}
                            handleChange={(value) =>
                                handleToggle(item.id, value)
                            }
                        />
                    </td>
                    <td className="px-6 py-4">
                        {item.video_created_at
                            ? moment(item.video_created_at).format('YYYY-MM-DD')
                            : '-'}
                    </td>
                    <td className="px-6 py-4 text-right">
                        <Menu3dots>
                            {({ open }) => (
                                <>
                                    <Menu3dots.Button
                                        as={
                                            isMutating ? React.Fragment : 'span'
                                        }
                                    >
                                        <Button3dots
                                            open={open}
                                            loading={isMutating}
                                        />
                                    </Menu3dots.Button>
                                    <Menu3dots.Items>
                                        <>
                                            {handleEdit && (
                                                <div className="divide-y border-t-[1px] border-gray-700 py-1">
                                                    <Menu3dots.Item
                                                        onClick={() =>
                                                            handleEdit
                                                                ? handleEdit(
                                                                      item.id,
                                                                      item
                                                                  )
                                                                : {}
                                                        }
                                                    >
                                                        <PencilAltIcon className="w-5 h-5 mr-3" />
                                                        {t(
                                                            'list.plotter_type_video.edit'
                                                        )}
                                                    </Menu3dots.Item>
                                                </div>
                                            )}

                                            {handleRemove && (
                                                <div className="divide-y border-t-[1px] border-gray-700 divide-gray-200 py-1">
                                                    <Menu3dots.Item
                                                        onClick={() =>
                                                            handleRemove
                                                                ? handleRemove(
                                                                      item.id
                                                                  )
                                                                : {}
                                                        }
                                                    >
                                                        <TrashIcon className="w-5 h-5 mr-3" />
                                                        {t(
                                                            'list.plotter_type_video.delete'
                                                        )}
                                                    </Menu3dots.Item>
                                                </div>
                                            )}
                                        </>
                                    </Menu3dots.Items>
                                </>
                            )}
                        </Menu3dots>
                    </td>
                </Tr>
            ))}
        </Tbody>
    )
}

export default PlotterTypeVideoList
