import { useQuery, useMutation } from 'react-query'
import qs from 'qs'

import client from '../client'
import { QUERY_CACHE_PLOTTER_TYPES_KEY } from '../../constants/index'

import type {
    UseQueryOptions,
    UseQueryResult,
    UseMutationOptions,
} from 'react-query'
import type {
    ResponseData,
    ResponseList,
    ResponseError,
    PlotterTypeResponse,
    PlotterTypeListResponse,
    DeleteVariables,
    UpdatePlotterTypeVariables,
    CreatePlotterTypeVariables,
    PlotterTypeWithTutorialsResponse,
} from '../types'

export const getPlotterType = <T>(filters?: {}): Promise<T> =>
    client.get(
        '/cutter-types' + (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const usePlotterTypeQuery = <T = ResponseList<PlotterTypeListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_PLOTTER_TYPES_KEY, 'index', filters],
        () => getPlotterType<T>(filters),
        options
    )

export const getPlotterTypeById = (
    id: string
): Promise<ResponseData<PlotterTypeWithTutorialsResponse>> =>
    client.get(`/cutter-types/${id}`)

export const useGetPlotterTypeByIdQuery = <
    TData = ResponseData<PlotterTypeWithTutorialsResponse>,
>(
    id: string,
    queryOptions?: UseQueryOptions<
        ResponseData<PlotterTypeWithTutorialsResponse>,
        ResponseError,
        TData,
        [string, string]
    >
): UseQueryResult<TData, ResponseError> =>
    useQuery(
        [QUERY_CACHE_PLOTTER_TYPES_KEY, id],
        () => getPlotterTypeById(id),
        queryOptions
    )

const createPlotterType = ({
    data,
}: CreatePlotterTypeVariables): Promise<ResponseData<PlotterTypeResponse>> =>
    client.post('/cutter-types', data)

export const useCreatePlotterType = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<PlotterTypeResponse>,
            ResponseError,
            CreatePlotterTypeVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => createPlotterType(data), options)

const updatePlotterType = ({
    id,
    data,
}: UpdatePlotterTypeVariables): Promise<ResponseData<PlotterTypeResponse>> =>
    client.patch(`/cutter-types/${id}`, data)

export const useUpdatePlotterType = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<PlotterTypeResponse>,
            ResponseError,
            UpdatePlotterTypeVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => updatePlotterType(data), options)

const deletePlotterType = ({
    id,
}: DeleteVariables): Promise<ResponseData<void>> =>
    client.delete(`/cutter-types/${id}`)

export const useDeletePlotterType = (
    options?: Omit<
        UseMutationOptions<ResponseData<void>, ResponseError, DeleteVariables>,
        'mutationFn'
    >
) => useMutation((data) => deletePlotterType(data), options)
