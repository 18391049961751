import React, { useState, useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { CloudDownloadIcon } from '@heroicons/react/outline'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { useEmployeeByIdQuery, useStatisticsDetailsQuery } from 'api'
import {
    defaultFrom,
    defaultTo,
    listReducer,
    lowerLimitDate,
    upperLimitDate,
} from 'helpers'
import { FilterButton } from 'components/ui'
import PageLayout from 'components/PageLayout'
import StatisticsDetailsList from 'components/StatisticsDetailsList'
import StatisticsDetailsListFilters from 'components/StatisticsDetailsListFilters'

import type {
    StatisticsDetailsListFilterState,
    StatisticsDetailsListFilterAction,
    ListFilterAction,
} from '../types'
import moment from 'moment'
import { DatePicker } from 'components/ui/DatePickers'
import { useModal } from 'hooks'
import ExportModal from 'components/ExportModal'
import { useParams } from 'react-router-dom'
import { usePolicy } from 'hooks/usePolicy'

const filterReducer = (
    state: StatisticsDetailsListFilterState,
    action: StatisticsDetailsListFilterAction
) => {
    if (action.type === 'setFilterSuperior') {
        return {
            ...state,
            superior: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterUser') {
        return {
            ...state,
            user: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterDate') {
        return {
            ...state,
            from: action.payload.from,
            to: action.payload.to,
        }
    }

    if (action.type === 'setFilterFrom') {
        return {
            ...state,
            status: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterTo') {
        return {
            ...state,
            role: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterCountry') {
        return {
            ...state,
            country: action.payload,
            city: undefined,
            page: 1,
        }
    }

    if (action.type === 'setFilterRegion') {
        return {
            ...state,
            region: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterCity') {
        return {
            ...state,
            city: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterPlotter') {
        return {
            ...state,
            plotter: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterDevice') {
        return {
            ...state,
            pattern: undefined,
            device: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterType') {
        return {
            ...state,
            type: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterSize') {
        return {
            ...state,
            size: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterPattern') {
        return {
            ...state,
            pattern: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterFeedbackStatus') {
        return {
            ...state,
            feedbackType: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            superior: undefined,
            user: undefined,
            country: undefined,
            city: undefined,
            region: undefined,
            device: undefined,
            size: undefined,
            type: undefined,
            pattern: undefined,
            plotter: undefined,
            feedbackType: undefined,
            status: undefined,
            length: DEFAULT_LIST_PER_PAGE,
            page: 1,
        }
    }

    return listReducer(
        state,
        action as ListFilterAction
    ) as StatisticsDetailsListFilterState
}

export default function StatisticsDetailsListView() {
    const { employee_id, protection_film_id } = useParams()

    const { t } = useTranslation()

    const exportModal = useModal<StatisticsDetailsListFilterState>()

    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        from: defaultFrom,
        to: defaultTo,
        search_global: '',
        superior: undefined,
        user: undefined,
        country: undefined,
        city: undefined,
        region: undefined,
        device: undefined,
        size: undefined,
        type: undefined,
        pattern: undefined,
        feedbackType: undefined,
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)

    const filterCount = useMemo(
        () =>
            (filters.superior ? 1 : 0) +
            (filters.user ? 1 : 0) +
            (filters.country ? 1 : 0) +
            (filters.city ? 1 : 0) +
            (filters.plotter ? 1 : 0) +
            (filters.region ? 1 : 0) +
            (filters.device ? 1 : 0) +
            (filters.size ? 1 : 0) +
            (filters.type ? 1 : 0) +
            (filters.feedbackType ? 1 : 0) +
            (filters.pattern ? 1 : 0),
        [filters]
    )

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            from: moment(filters.from || defaultFrom).format('YYYY-MM-DD'),
            to: moment(filters.to || defaultTo).format('YYYY-MM-DD'),
            search_global: filters.search_global,
            superior_id: filters.superior?.id,
            user_id: filters.user?.id,
            country_id: filters.country?.id,
            city_id: filters.city?.id,
            region: filters.region,
            device_id: filters.device?.id,
            cutter_id: filters.plotter?.id,
            employee_id: employee_id,
            protection_film_id: protection_film_id,
            size: filters.size?.id,
            type: filters.type?.id,
            pattern_id: filters.pattern?.id,
            feedback_type: filters.feedbackType?.id,
        }
    }, [filters, employee_id, protection_film_id])

    const statisticsDetailsQuery = useStatisticsDetailsQuery(apiFilters)

    const filtersDisabled = useMemo(
        () => !!protection_film_id,
        [protection_film_id]
    )

    const policy = usePolicy()

    if (!policy.isAdmin() && !policy.isUser() && !policy.isSupervisor) {
        return <div>Forbidden</div>
    }

    return (
        <PageLayout
            renderTitle={
                <StatisticsDetailsTitle
                    employee_id={employee_id}
                    protection_film_id={protection_film_id}
                />
            }
            searchBarAction={
                filtersDisabled
                    ? undefined
                    : (value) =>
                          dispatchFilterAction({
                              type: 'setFilterQuery',
                              payload: value,
                          })
            }
            actions={
                <>
                    <span className="w-32">
                        <DatePicker
                            disabled={filtersDisabled}
                            disabledBefore={lowerLimitDate}
                            disabledAfter={
                                filters.to ? filters.to : upperLimitDate
                            }
                            startMonth={lowerLimitDate}
                            endMonth={upperLimitDate}
                            value={filters.from}
                            onChange={(value) =>
                                value &&
                                dispatchFilterAction({
                                    type: 'setFilterDate',
                                    payload: {
                                        from: value,
                                        to: filters.to,
                                    },
                                })
                            }
                        />
                    </span>
                    <span className="w-32 ml-2 mr-6">
                        <DatePicker
                            disabled={filtersDisabled}
                            disabledBefore={
                                filters.from ? filters.from : lowerLimitDate
                            }
                            disabledAfter={upperLimitDate}
                            startMonth={lowerLimitDate}
                            endMonth={upperLimitDate}
                            value={filters.to}
                            onChange={(value) =>
                                value &&
                                dispatchFilterAction({
                                    type: 'setFilterDate',
                                    payload: {
                                        from: filters.from,
                                        to: value,
                                    },
                                })
                            }
                        />
                    </span>
                    <span className="pl-6 border-l border-white">
                        <div className="relative inline-flex">
                            <button
                                type="button"
                                disabled={filtersDisabled}
                                onClick={() => {
                                    exportModal.setState(filters)
                                    exportModal.openModal()
                                }}
                                className="relative flex items-center px-4 py-2 border border-gray-600 shadow-sm leading-5 text-sm font-medium rounded-md text-white bg-gray-800 disabled:opacity-30"
                            >
                                <span className="hidden md:inline">
                                    {t('action.export')}
                                </span>
                                <CloudDownloadIcon
                                    className="-ml-0.5 md:ml-2 h-4 w-4 text-gray-300"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </span>
                    <span className="ml-6">
                        <FilterButton
                            disabled={filtersDisabled}
                            count={filterCount}
                            filtersExpanded={filtersExpanded}
                            onClick={() =>
                                setFiltersExpanded((prevState) => !prevState)
                            }
                            handleReset={() =>
                                dispatchFilterAction({
                                    type: 'resetFilters',
                                })
                            }
                        />
                    </span>
                </>
            }
        >
            <>
                {filtersExpanded && (
                    <StatisticsDetailsListFilters
                        filters={filters}
                        filterAction={dispatchFilterAction}
                    />
                )}
                <StatisticsDetailsList
                    statisticsDetailsQuery={statisticsDetailsQuery}
                    filters={filters}
                    filterCount={filterCount}
                    filterAction={dispatchFilterAction}
                />
                {exportModal.isOpen && <ExportModal modal={exportModal} />}
            </>
        </PageLayout>
    )
}

function StatisticsDetailsTitle({
    employee_id,
    protection_film_id,
}: {
    employee_id?: string
    protection_film_id?: string
}) {
    if (employee_id != null) {
        return <StatisticsDetailsEmployeeTitle employeeId={employee_id} />
    }

    if (protection_film_id != null) {
        return <StatisticsDetailsComplaintCodeTitle />
    }

    return <StatisticsDetailsStandardTitle />
}

function StatisticsDetailsComplaintCodeTitle() {
    const { t } = useTranslation()
    const title = t('list.report.complaint_code_title')

    return <>{title}</>
}

function StatisticsDetailsStandardTitle() {
    const { t } = useTranslation()
    const title = t('list.report.title')

    return <>{title}</>
}

function StatisticsDetailsEmployeeTitle({
    employeeId,
}: {
    employeeId: string
}) {
    const employeeQuery = useEmployeeByIdQuery(employeeId)

    if (employeeQuery.isLoading) {
        return <></>
    }

    if (employeeQuery.isError) {
        return <div className="text-red-600">{employeeQuery.error.message}</div>
    }

    if (!employeeQuery.isSuccess) {
        return null
    }

    return <>{employeeQuery.data.data.name}</>
}
