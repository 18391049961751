import { useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import {
    DEFAULT_LIST_PER_PAGE,
    QUERY_CACHE_BLADE_TYPES_KEY,
} from '../constants/index'
import { useBladeTypeQuery } from '../api'
import { listReducer } from '../helpers'
import { Button, Loader } from '../components/ui'
import PageLayout from '../components/PageLayout'
import BladeTypeList from '../components/BladeTypeList'

import type {
    BladeTypeListFilterState,
    PlotterTypeListFilterAction,
    ListFilterAction,
    FormSubmitFn,
    BladeTypeForm,
} from '../types'
import ConfirmModal from '../components/ConfirmModal'
import { useDeleteBladeTypeForm } from '../hooks/forms/useDeleteBladeTypeForm'
import { useQueryClient } from 'react-query'
import { useFlashMessage, useModal } from '../hooks'
import { BladeTypeResponse } from '../api/types'
import { usePolicy } from '../hooks/usePolicy'
import BladeTypeModal from 'components/BladeTypeModal'
import {
    useCreateBladeType,
    useProtectionFilmTypeQuery,
    useUpdateBladeType,
} from 'api/endpoints'

const filterReducer = (
    state: BladeTypeListFilterState,
    action: PlotterTypeListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            page: 1,
        }
    }

    return listReducer(
        state,
        action as ListFilterAction
    ) as BladeTypeListFilterState
}

export default function BladeTypeListView() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const createBladeTypeModal = useModal()
    const updateBladeTypeModal = useModal<{
        id: number
        payload: BladeTypeResponse
    }>()
    const createBladeType = useCreateBladeType()
    const updateBladeType = useUpdateBladeType()

    const handleSubmit: FormSubmitFn<BladeTypeForm> = (
        values,
        formikHelpers
    ) => {
        createBladeType.mutate(
            {
                data: {
                    name: values.name,
                    protection_film_types: Object.entries(values.filmTypes).map(
                        ([id, value]) => ({
                            id: parseInt(id),
                            cuts_number: value,
                        })
                    ),
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.blade.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_BLADE_TYPES_KEY,
                        'index',
                    ])
                    createBladeTypeModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleSubmitEdit: FormSubmitFn<BladeTypeForm> = (
        values,
        formikHelpers
    ) => {
        updateBladeType.mutate(
            {
                id: updateBladeTypeModal.getState()!.id,
                data: {
                    name: values.name,
                    protection_film_types: Object.entries(values.filmTypes).map(
                        ([id, value]) => ({
                            id: parseInt(id),
                            cuts_number: value,
                        })
                    ),
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.blade.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_BLADE_TYPES_KEY,
                        'index',
                    ])
                    updateBladeTypeModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const deleteBladeForm = useDeleteBladeTypeForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_BLADE_TYPES_KEY, 'index'])
    })

    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        sort_by: 'id',
        sort_direction: 'desc',
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            status: undefined,
            search_global: filters.search_global || undefined,
        }
    }, [filters])

    const bladeTypesQuery = useBladeTypeQuery(apiFilters)
    const protectionFilmTypesQuery = useProtectionFilmTypeQuery({ limit: 999 })

    const policy = usePolicy()

    if (!policy.isAdmin() && !policy.isEditor()) {
        return <div>Forbidden</div>
    }

    if (protectionFilmTypesQuery.isLoading) {
        return <Loader />
    }

    if (protectionFilmTypesQuery.isError) {
        return (
            <div className="text-red-600">
                {protectionFilmTypesQuery.error.message}
            </div>
        )
    }

    if (!protectionFilmTypesQuery.isSuccess) {
        return null
    }

    return (
        <PageLayout
            title={t('blade.list.title')}
            searchBarAction={(value) =>
                dispatchFilterAction({
                    type: 'setFilterQuery',
                    payload: value,
                })
            }
            actions={
                <>
                    <span className="ml-2">
                        <Button onClick={createBladeTypeModal.openModal}>
                            {t('form.add')}
                        </Button>
                    </span>
                </>
            }
        >
            <>
                <BladeTypeList
                    bladeTypesQuery={bladeTypesQuery}
                    filters={filters}
                    filmTypes={protectionFilmTypesQuery.data.data}
                    filterCount={filters.search_global ? 1 : 0}
                    handleRemove={
                        policy.isAdmin()
                            ? (id) => {
                                  deleteBladeForm.modal.setState(id)
                                  deleteBladeForm.modal.openModal()
                              }
                            : undefined
                    }
                    handleEdit={
                        policy.isAdmin()
                            ? (id, payload) => {
                                  updateBladeTypeModal.setState({
                                      id,
                                      payload,
                                  })
                                  updateBladeTypeModal.openModal()
                              }
                            : undefined
                    }
                    filterAction={dispatchFilterAction}
                />
                <BladeTypeModal
                    modal={createBladeTypeModal}
                    filmTypes={protectionFilmTypesQuery.data.data}
                    onSubmit={handleSubmit}
                />
                <BladeTypeModal
                    modal={updateBladeTypeModal}
                    filmTypes={protectionFilmTypesQuery.data.data}
                    bladeTypeData={updateBladeTypeModal.getState()?.payload}
                    onSubmit={handleSubmitEdit}
                />
                <ConfirmModal
                    modal={deleteBladeForm.modal}
                    title={t('delete.blade.title')}
                    description={t('delete.blade.description')}
                    actionLabel={t('action.delete')}
                    onSubmit={deleteBladeForm.form.submitForm}
                    isSubmitting={deleteBladeForm.form.isSubmitting}
                />
            </>
        </PageLayout>
    )
}
