import React from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import { getCxFromStyles } from '../helpers'
import Modal from './Modal'
import { Button, Label } from './ui'
import { UploaderState } from './FileUploader'
import { TextField, FileUploaderField } from './forms'

import type { FormSubmitFn, IModal, PlotterTypeVideoForm } from '../types'

import styles from './Modal.module.scss'
import { PlotterTypeVideoResponse } from '../api/types'

export type PlotterTypeVideoModalProps = {
    modal: IModal
    plotterTypeVideoData?: PlotterTypeVideoResponse
    onSubmit: FormSubmitFn<PlotterTypeVideoForm>
}

export default function PlotterTypeVideoModal({
    modal,
    plotterTypeVideoData,
    onSubmit,
}: PlotterTypeVideoModalProps) {
    const cx = getCxFromStyles(styles)
    const { t } = useTranslation()

    const initialValues: PlotterTypeVideoForm = plotterTypeVideoData
        ? {
              name: plotterTypeVideoData.name,
              link: plotterTypeVideoData.link,
              video: 0,
              cover: 0,
          }
        : {
              name: '',
              link: '',
              video: 0,
              cover: 0,
          }

    return (
        <Modal modal={modal} type="default">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg pb-6 leading-6 font-medium text-white text-center"
                >
                    {plotterTypeVideoData
                        ? t('form.plotter_type_video.edit_title')
                        : t('form.plotter_type_video.title')}
                </DialogTitle>
                <Formik<PlotterTypeVideoForm>
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className="px-2 pb-2">
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t(
                                            'form.plotter_type_video.field.name'
                                        )}
                                    </Label>
                                    <TextField name="name" />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t(
                                            'form.plotter_type_video.field.link'
                                        )}
                                    </Label>
                                    <TextField name="link" />
                                </div>
                            </div>
                            <div className="mr-auto max-w-3xl grid grid-cols-1 gap-4 pb-4">
                                <div className="col-span-1">
                                    <FileUploaderField
                                        name="video"
                                        fileId={values.video}
                                        acceptFormats={{
                                            'video/mp4': ['.mp4'],
                                        }}
                                        isMultiple={false}
                                        limit={10}
                                        handleUploadSuccess={(
                                            state: UploaderState
                                        ) =>
                                            setFieldValue(
                                                'video',
                                                state.addedFile.id
                                            )
                                        }
                                        handleRemoveFile={() =>
                                            setFieldValue('video', 0)
                                        }
                                        addedFile={
                                            !!plotterTypeVideoData &&
                                            !!plotterTypeVideoData.video_name
                                                ? {
                                                      file: {},
                                                      title: `${plotterTypeVideoData.video_name}.mp4`,
                                                  }
                                                : undefined
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mr-auto max-w-3xl grid grid-cols-1 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.plotter_type_video.field.png')}
                                    </Label>
                                    <FileUploaderField
                                        name="cover"
                                        fileId={values.cover}
                                        acceptFormats={{
                                            'image/png': ['.png'],
                                            'image/jpeg': ['.jpg', '.jpeg'],
                                        }}
                                        isMultiple={false}
                                        handleUploadSuccess={(
                                            state: UploaderState
                                        ) =>
                                            setFieldValue(
                                                'cover',
                                                state.addedFile.id
                                            )
                                        }
                                        handleRemoveFile={() =>
                                            setFieldValue('cover', 0)
                                        }
                                        addedFile={
                                            !!plotterTypeVideoData &&
                                            !!plotterTypeVideoData.cover_name
                                                ? {
                                                      file: {},
                                                      title: `${plotterTypeVideoData.cover_name}.png`,
                                                  }
                                                : undefined
                                        }
                                    />
                                </div>
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    {t('form.save')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
