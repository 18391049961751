import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import {
    ScissorsIcon,
    DocumentTextIcon,
    PresentationChartBarIcon,
    CollectionIcon,
    InboxIcon,
} from '@heroicons/react/outline'
import { usePolicy } from './usePolicy'
import { KeyIcon } from '@heroicons/react/solid'

export type Item = {
    name: string
    to: string
    icon?: React.FC<any>
    exact?: boolean
    disabled?: true
}

export type SubItem = {
    name: string
    to: string
    matches?: string[]
    exclude?: string[]
    exact?: boolean
}

export const useSidebarItems = (): Item[] => {
    const { t } = useTranslation()
    const policy = usePolicy()

    return useMemo(
        () =>
            [
                (policy.isAdmin() ||
                    policy.isSupervisor() ||
                    policy.isUser()) && {
                    name: t('sidebar.stats'),
                    to: '/stats',
                    exact: false,
                    icon: PresentationChartBarIcon,
                },
                (policy.isAdmin() ||
                    policy.isTrainer() ||
                    policy.isServiceman()) && {
                    name: t('sidebar.clients'),
                    to: '/role/user',
                    exact: false,
                },
                (policy.isAdmin() ||
                    policy.isTrainer() ||
                    policy.isServiceman()) && {
                    name: t('sidebar.supervisors'),
                    to: '/role/superior',
                    exact: false,
                },
                (policy.isAdmin() ||
                    policy.isSupervisor() ||
                    policy.isUser()) && {
                    name: t('sidebar.report'),
                    to: '/report',
                    exact: false,
                },
                (policy.isAdmin() || policy.isEditor()) && {
                    name: t('sidebar.brand'),
                    to: '/brand',
                    exact: false,
                    icon: DocumentTextIcon,
                },
                (policy.isAdmin() || policy.isEditor()) && {
                    name: t('sidebar.device'),
                    to: '/device',
                    exact: false,
                    icon: CollectionIcon,
                },
                (policy.isAdmin() || policy.isEditor()) && {
                    name: t('sidebar.patterns'),
                    to: '/patterns',
                    exact: false,
                },
                (policy.isAdmin() ||
                    policy.isSupervisor() ||
                    policy.isUser() ||
                    policy.isTrainer() ||
                    policy.isServiceman()) && {
                    name: t('sidebar.plotters'),
                    to: '/plotter',
                    exact: false,
                    icon: ScissorsIcon,
                },
                policy.isAdmin() && {
                    name: t('sidebar.plotter_types'),
                    to: '/machine-type',
                    exact: false,
                },
                policy.isAdmin() && {
                    name: t('sidebar.blade_types'),
                    to: '/blade-type',
                    exact: false,
                },
                policy.isAdmin() && {
                    name: t('sidebar.qr'),
                    to: '/qr-generator',
                    exact: false,
                    icon: InboxIcon,
                },
                (policy.isAdmin() || policy.isServiceman()) && {
                    name: t('sidebar.complaint_codes'),
                    to: '/complaint-codes',
                    exact: false,
                },
                (policy.isAdmin() ||
                    policy.isServiceman() ||
                    policy.isTrainer()) && {
                    name: t('sidebar.service_pin'),
                    to: '/service-pin',
                    exact: false,
                    icon: KeyIcon,
                },
                policy.isAdmin() && {
                    name: t('sidebar.protection_film_types'),
                    to: '/protection-film-types',
                    exact: false,
                },
                (policy.isAdmin() ||
                    policy.isSupervisor() ||
                    policy.isTrainer() ||
                    policy.isServiceman()) && {
                    name: t('sidebar.users'),
                    to: '/user',
                    exact: false,
                },
            ].filter((item) => !!item) as Item[],
        [t, policy]
    )
}
