import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { PlusIcon } from '@heroicons/react/outline'
import { Tab, TabGroup, TabList, TabPanels } from '@headlessui/react'

import { Button } from './ui'

export default function UserTabView({
    children,
    onAction,
    haveUsers,
    haveEmployees,
    haveReports,
}: {
    children: React.ReactNode
    onAction?: () => void
    onSecondaryAction?: () => void
    haveUsers: boolean
    haveEmployees: boolean
    haveReports: boolean
}) {
    const { hash } = useLocation()
    const tabId = useMemo(() => hash.replace('#', ''), [hash])

    const { t } = useTranslation()

    const onTabChange = useCallback((index: number) => {
        window.history.replaceState(null, '', `#${index}`)
    }, [])

    return (
        <TabGroup
            defaultIndex={typeof tabId != 'undefined' ? parseInt(tabId) : 0}
            onChange={onTabChange}
        >
            <div className="flex space-between">
                {/*border-b border-gray-600*/}
                <TabList className="relative left-0 top-0 justify-center items-center gap-8 inline-flex border-b-gray-600">
                    <TabElement label={t('form.user.tabs.data')} />
                    <TabElement label={t('form.user.tabs.features')} />
                    <TabElement
                        disabled={!haveUsers}
                        label={t('form.user.tabs.users')}
                    />
                    <TabElement
                        disabled={!haveReports}
                        label={t('form.user.tabs.report')}
                    />
                    <TabElement
                        disabled={!haveEmployees}
                        label={t('form.user.tabs.employees')}
                    />
                </TabList>
                <div className="ml-auto">
                    {onAction && (
                        <Button onClick={onAction}>
                            <PlusIcon className="w-5 h-5 mr-2" />
                            {t('form.user.add_plotter')}
                        </Button>
                    )}
                    {/* {onSecondaryAction && (
                        <Button className="ml-4" onClick={onSecondaryAction}>
                            <PlusIcon className="w-5 h-5 mr-2" />
                            {t('form.user.report')}
                        </Button>
                    )} */}
                </div>
            </div>
            <TabPanels>{children}</TabPanels>
        </TabGroup>
    )
}

const TabElement = ({
    label,
    disabled,
}: {
    label: string
    disabled?: boolean
}) => (
    <Tab
        disabled={disabled}
        className={classNames(
            'h-full data-[selected]:text-red-600 data-[selected]:border-b-2 border-red-600 text-sm leading-5 font-medium p-1',
            {
                'text-gray-500': disabled,
                'text-white': !disabled,
            }
        )}
    >
        {label}
    </Tab>
)
