import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { FilterIcon, XIcon } from '@heroicons/react/solid'

const FilterButton: React.FC<{
    count: number
    disabled?: boolean
    onClick: () => void
    handleReset?: () => void
    filtersExpanded?: boolean
}> = ({ count, disabled, filtersExpanded, onClick, handleReset }) => {
    const { t } = useTranslation()

    return (
        <div className="relative inline-flex">
            {!!count && !!handleReset && (
                <div
                    className="absolute z-10 -top-2.5 -right-2.5"
                    onClick={handleReset}
                >
                    <div className="flex w-5 h-5 items-center justify-center bg-white rounded-full text-[#141313] ring-2 ring-[#141313] cursor-pointer">
                        <XIcon className="w-3.5" />
                    </div>
                </div>
            )}
            <button
                type="button"
                disabled={disabled}
                onClick={onClick}
                className={classNames(
                    'relative flex items-center px-4 py-2 border border-gray-600 shadow-sm leading-5 text-sm font-medium rounded-md text-white bg-gray-800 disabled:opacity-30',
                    {
                        'outline-none ring-2 ring-offset-2 ring-offset-[#141313] ring-red-600':
                            filtersExpanded,
                    }
                )}
            >
                {!!count && (
                    <span className="absolute -top-2.5 -left-2.5 w-5 h-5 shadow-md inline-flex items-center justify-center rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                        {count}
                    </span>
                )}
                <span className="hidden md:inline">{t('action.filters')}</span>
                <FilterIcon
                    className="-ml-0.5 md:ml-2 h-4 w-4 text-gray-300"
                    aria-hidden="true"
                />
            </button>
        </div>
    )
}

export default FilterButton
