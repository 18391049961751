import React, { useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import {
    DEFAULT_LIST_PER_PAGE,
    QUERY_CACHE_COMPLAINTS_KEY,
} from '../constants/index'
import { useCreateComplaint, useComplaintsQuery } from '../api'
import { useFlashMessage, useModal, useTimespansDropdown } from '../hooks'
import { useQueryClient } from 'react-query'

import { Button, Label } from '../components/ui'

import type {
    ComplaintForm,
    ComplaintListFilterAction,
    ComplaintListFilterState,
    FormSubmitFn,
    ListFilterAction,
} from '../types'
import ConfirmModal from '../components/ConfirmModal'
import ComplaintList from '../components/ComplaintCodeList'
import { listReducer } from 'helpers'
import { useDeleteComplaintForm } from 'hooks/forms/useDeleteComplaintForm'
import { Form, Formik } from 'formik'
import { DropdownField, TextField } from 'components/forms'
import PageLayout from 'components/PageLayout'
import { Link } from 'react-router-dom'
import { usePolicy } from 'hooks/usePolicy'

const filterReducer = (
    state: ComplaintListFilterState,
    action: ComplaintListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            status: undefined,
            page: 1,
        }
    }

    return listReducer(
        state,
        action as ListFilterAction
    ) as ComplaintListFilterState
}

export default function ComplaintCodeListView() {
    const { t } = useTranslation()

    const timespans = useTimespansDropdown()

    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        sort_by: 'id',
        sort_direction: 'desc',
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })

    const filterCount = useMemo(() => 0, [])

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            search_global: filters.search_global || undefined,
        }
    }, [filters])

    const complaintsQuery = useComplaintsQuery(apiFilters)

    const flashMessage = useFlashMessage()
    const queryClient = useQueryClient()
    const createComplaintModal = useModal()
    const createComplaint = useCreateComplaint()

    const deletePlotterForm = useDeleteComplaintForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_COMPLAINTS_KEY, 'index'])
    })

    const handleSubmit: FormSubmitFn<ComplaintForm> = (
        values,
        formikHelpers
    ) => {
        createComplaint.mutate(
            {
                data: {
                    count: values.count,
                    validity_time:
                        typeof values.validity_time?.id === 'number'
                            ? values.validity_time?.id
                            : undefined,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.complaint.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_COMPLAINTS_KEY,
                    ])
                    createComplaintModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const policy = usePolicy()

    if (!policy.isAdmin() && !policy.isServiceman()) {
        return <div>Forbidden</div>
    }

    return (
        <PageLayout
            title={t('complaint.list.title')}
            searchBarAction={(value) =>
                dispatchFilterAction({
                    type: 'setFilterQuery',
                    payload: value,
                })
            }
            actions={<></>}
        >
            <div className="mt-8">
                <Formik<ComplaintForm>
                    initialValues={{
                        count: 0,
                    }}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, isSubmitting }) => (
                        <Form>
                            <div className="p-6 rounded-lg bg-gray-800 mb-12">
                                <h2 className="text-xl leading-7 font-medium">
                                    {t('form.complaint.subtitle')}
                                </h2>
                                <div className="my-6 grid grid-cols-2 gap-4">
                                    <div className="col-span-1">
                                        <Label>
                                            {t('form.complaint.field.timespan')}
                                        </Label>
                                        <DropdownField
                                            name="validity_time"
                                            value={values.validity_time}
                                            items={timespans}
                                            anchor="top"
                                            onChange={(value) =>
                                                setFieldValue(
                                                    'validity_time',
                                                    value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="col-span-1">
                                        <Label>
                                            {t('form.complaint.field.count')}
                                        </Label>
                                        <TextField name="count" />
                                    </div>
                                </div>

                                <div className="">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        loading={isSubmitting}
                                    >
                                        {t('form.generate')}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className="pb-12 pt-8 flex justify-between">
                    <h2 className="text-xl leading-7 font-medium">
                        {t('list.complaint.title')}
                    </h2>
                    <Link
                        to="/complaint-codes/used"
                        className="flex items-center gap-3 shadow-sm bg-red-100 text-red-700 px-3 py-2 rounded-md"
                    >
                        {t('list.complaint.used_link')}
                    </Link>
                </div>
                <div>
                    <ComplaintList
                        filters={filters}
                        filterCount={filterCount}
                        handleRemove={(id) => {
                            deletePlotterForm.modal.setState(id)
                            deletePlotterForm.modal.openModal()
                        }}
                        filterAction={dispatchFilterAction}
                        complaintsQuery={complaintsQuery}
                    />
                    <ConfirmModal
                        modal={deletePlotterForm.modal}
                        title={t('delete.complaint.title')}
                        description={t('delete.complaint.description')}
                        actionLabel={t('delete.complaint.action')}
                        onSubmit={deletePlotterForm.form.submitForm}
                        isSubmitting={deletePlotterForm.form.isSubmitting}
                    />
                </div>
            </div>
        </PageLayout>
    )
}
