import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { QUERY_CACHE_SERVICEPIN_KEY } from '../constants/index'
import { useServicePinQuery } from '../api'
import { useFlashMessage } from '../hooks'
import { Loader } from '../components/ui'
import PageLayout from '../components/PageLayout'
import ServicePin from 'components/ServicePin'

import type { FormSubmitFn, GenerateServicePinForm } from '../types'
import { usePolicy } from 'hooks/usePolicy'
import {
    useGenerateServicePin,
    useDisableServicePin,
} from 'api/endpoints/service-pin'

export default function ServicePinView() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const flashMessage = useFlashMessage()
    const servicePinQuery = useServicePinQuery()
    const generateServicePin = useGenerateServicePin()
    const disableServicePin = useDisableServicePin()

    const handleSubmit: FormSubmitFn<GenerateServicePinForm> = (
        values,
        formikHelpers
    ) => {
        generateServicePin.mutate(
            {
                data: {
                    validity_time:
                        typeof values.validity_time?.id === 'number'
                            ? values.validity_time?.id
                            : undefined,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.service_pin.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_SERVICEPIN_KEY,
                    ])
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleDisableSubmit: FormSubmitFn<{}> = useCallback(
        (values, formikHelpers) => {
            disableServicePin.mutate(
                { id: servicePinQuery.data?.data?.id.toString()! },
                {
                    onSuccess: async () => {
                        flashMessage({
                            type: 'success',
                            content: t('info.service_pin.deactivated'),
                        })
                        formikHelpers.setSubmitting(false)
                        await queryClient.invalidateQueries([
                            QUERY_CACHE_SERVICEPIN_KEY,
                        ])
                    },
                    onError: (error) => {
                        formikHelpers.setErrors(error.errors)
                        formikHelpers.setSubmitting(false)
                    },
                }
            )
        },
        [
            queryClient,
            flashMessage,
            t,
            disableServicePin,
            servicePinQuery.data?.data,
        ]
    )

    const policy = usePolicy()

    if (!policy.isAdmin() && !policy.isServiceman() && !policy.isTrainer()) {
        return <div>Forbidden</div>
    }

    if (servicePinQuery.isLoading) {
        return <Loader />
    }

    if (servicePinQuery.isError) {
        return (
            <div className="text-red-600">{servicePinQuery.error.message}</div>
        )
    }

    if (!servicePinQuery.isSuccess) {
        return null
    }

    return (
        <PageLayout title={t('service_pin.title')}>
            <>
                <div>
                    <ServicePin
                        onSubmit={handleSubmit}
                        onDisableServicePin={handleDisableSubmit}
                        expire_at={
                            servicePinQuery.data.data?.active
                                ? servicePinQuery.data.data?.expire_at
                                : undefined
                        }
                        servicePin={
                            servicePinQuery.data.data?.active
                                ? servicePinQuery.data.data?.code?.slice(0, 4) +
                                  ' ' +
                                  servicePinQuery.data.data?.code?.slice(4)
                                : undefined
                        }
                    />
                </div>
            </>
        </PageLayout>
    )
}
