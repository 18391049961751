import React from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import {
    lowerPhoneLimitDate,
    getCxFromStyles,
    upperPhoneLimitDate,
} from '../helpers'
import Modal from './Modal'
import { Button, Label } from './ui'
import { UploaderState } from './FileUploader'
import {
    TextField,
    DropdownField,
    DatePickerField,
    FileUploaderField,
} from './forms'

import type { FormSubmitFn, IModal, DeviceForm } from '../types'

import styles from './Modal.module.scss'
import { DeviceResponse } from '../api/types'
import { useBrandQuery } from 'api'
import { useDeviceTypesDropdown } from 'hooks'

export type DeviceModalProps = {
    modal: IModal
    deviceData?: DeviceResponse
    onSubmit: FormSubmitFn<DeviceForm>
}

export default function DeviceModal({
    modal,
    deviceData,
    onSubmit,
}: DeviceModalProps) {
    const cx = getCxFromStyles(styles)
    const { t } = useTranslation()

    const brands = useBrandQuery({ length: 99 })
    const types = useDeviceTypesDropdown()

    const initialValues: DeviceForm = deviceData
        ? {
              name: deviceData.name,
              type: types.find(({ id }) => id === deviceData.type),
              tags: deviceData.tags,
              brand: brands.data?.data.find(
                  ({ id }) => id === deviceData.brand.id
              ),
              release_date: deviceData.release_date
                  ? new Date(deviceData.release_date)
                  : undefined,
              image: 0,
          }
        : {
              name: '',
              tags: '',
              type: undefined,
              brand: undefined,
              release_date: undefined,
              image: 0,
          }

    return (
        <Modal modal={modal} type="default">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg pb-6 leading-6 font-medium text-white text-center"
                >
                    {deviceData
                        ? t('form.device.edit_title')
                        : t('form.device.title')}
                </DialogTitle>
                <Formik<DeviceForm>
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className="px-2 pb-2">
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>{t('form.device.field.name')}</Label>
                                    <TextField name="name" />
                                </div>
                                <div className="col-span-1">
                                    <Label>{t('form.device.field.type')}</Label>
                                    <DropdownField
                                        name="type"
                                        value={values.type}
                                        items={types}
                                        onChange={(value) =>
                                            setFieldValue('type', value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.device.field.brand')}
                                    </Label>
                                    <DropdownField
                                        name="brand"
                                        value={values.brand}
                                        items={brands.data?.data || []}
                                        onChange={(value) =>
                                            setFieldValue('brand', value)
                                        }
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>{t('form.device.field.date')}</Label>
                                    <DatePickerField
                                        startMonth={lowerPhoneLimitDate}
                                        endMonth={upperPhoneLimitDate}
                                        name="release_date"
                                        value={values.release_date}
                                        onChange={(value) =>
                                            value &&
                                            setFieldValue('release_date', value)
                                        }
                                        placeholder={t(
                                            'form.device.field.date'
                                        )}
                                        captionLayout="dropdown-years"
                                    />
                                </div>
                            </div>
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                <div className="col-span-2">
                                    <Label>
                                        {t('form.device.field.tags')}&nbsp;
                                        <span className="text-gray-400">
                                            ({t('form.device.field.tags_tip')})
                                        </span>
                                    </Label>
                                    <TextField name="tags" />
                                </div>
                            </div>
                            <div className="mr-auto max-w-3xl grid grid-cols-1 gap-4 pb-4">
                                <FileUploaderField
                                    name="image"
                                    fileId={values.image}
                                    acceptFormats={{ 'image/png': ['.png'] }}
                                    isMultiple={false}
                                    handleUploadSuccess={(
                                        state: UploaderState
                                    ) =>
                                        setFieldValue(
                                            'image',
                                            state.addedFile.id
                                        )
                                    }
                                    handleRemoveFile={() =>
                                        setFieldValue('image', 0)
                                    }
                                    addedFile={
                                        !!deviceData
                                            ? {
                                                  file: {},
                                                  title: `${deviceData.image_name}.png`,
                                              }
                                            : undefined
                                    }
                                />
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    {t('form.save')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
