import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Spinner } from './ui'
import TableFooter from './TableFooter'
import { Toggle, Button3dots } from './ui'
import Menu3dots from './Menu3dots'
import {
    TableHeader,
    TableContainer,
    Table,
    Tbody,
    Tempty,
    Tr,
} from './ui/List'

import { PencilAltIcon } from '@heroicons/react/solid'
import { PresentationChartBarIcon, TrashIcon } from '@heroicons/react/outline'

import type { UseQueryResult } from 'react-query'
import type {
    EmployeeListFilterState,
    EmployeeListFilterAction,
} from '../types'
import type {
    ResponseList,
    EmployeeListResponse,
    EmployeeResponse,
} from '../api/types'

type EmployeeListProps = {
    employeesQuery: UseQueryResult<ResponseList<EmployeeListResponse>>
    filters: EmployeeListFilterState
    filterCount: number
    filterAction: React.Dispatch<EmployeeListFilterAction>
    handleToggle: (id: number, state: boolean) => void
    handleEdit: (id: number, payload: EmployeeResponse) => void
}

type TableProps = {
    data: EmployeeListResponse
    handleToggle: (id: number, state: boolean) => void
    handleEdit: (id: number, payload: EmployeeResponse) => void
}

const EmployeeList: React.FC<EmployeeListProps> = ({
    employeesQuery,
    filters,
    handleToggle,
    filterCount,
    filterAction,
    handleEdit,
}) => {
    const { t } = useTranslation()

    return (
        <TableContainer>
            <Table>
                <TableHeader>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        <div className="text-left tracking-wider uppercase">
                            {t('list.employee.name')}
                        </div>
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.employee.email')}
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.employee.code')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[80px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        {t('list.employee.status')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[65px] px-6 py-4 text-left tracking-wider"
                    ></th>
                </TableHeader>
                {employeesQuery.isSuccess && employeesQuery.isFetched && (
                    <>
                        {employeesQuery.data.meta.total > 0 && (
                            <TableBody
                                data={employeesQuery.data.data}
                                handleToggle={handleToggle}
                                handleEdit={handleEdit}
                            />
                        )}
                    </>
                )}
                {employeesQuery.data?.meta.total === 0 && (
                    <Tbody>
                        <Tr key={-1} index={0}>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4 relative h-12">
                                {filterCount === 0 && (
                                    <Tempty>
                                        {t('list.employee.empty_list')}
                                    </Tempty>
                                )}
                                {filterCount > 0 && (
                                    <Tempty>
                                        {t('list.employee.empty_search')}
                                    </Tempty>
                                )}
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                        </Tr>
                    </Tbody>
                )}
            </Table>
            {employeesQuery.isLoading && (
                <div className="divide-y divide-gray-700 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (_, index) => (
                            <div
                                key={index}
                                className={classNames('relative h-[68px]', {
                                    'bg-gray-800': index % 2,
                                    'bg-background': !(index % 2),
                                })}
                            >
                                {index === 4 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            <TableFooter
                meta={employeesQuery.data?.meta}
                filterAction={filterAction}
            />
        </TableContainer>
    )
}

const TableBody: React.FC<TableProps> = ({
    data,
    handleToggle,
    handleEdit,
}) => {
    const { t } = useTranslation()

    const isMutating = false
    return (
        <Tbody>
            {data.map((item, index) => (
                <Tr key={item.id} index={index}>
                    <td className="px-6 py-4">{item.name}</td>
                    <td className="px-6 py-4">
                        <span>{item.email}</span>
                    </td>
                    <td className="px-6 py-4">
                        <span>{item.code}</span>
                    </td>
                    <td className="px-6 py-4">
                        <Toggle
                            checked={item.active}
                            handleChange={(value) =>
                                handleToggle(item.id, value)
                            }
                        />
                    </td>
                    <td className="px-6 py-4 text-right">
                        <Menu3dots>
                            {({ open }) => (
                                <>
                                    <Menu3dots.Button
                                        as={
                                            isMutating ? React.Fragment : 'span'
                                        }
                                    >
                                        <Button3dots
                                            open={open}
                                            loading={isMutating}
                                        />
                                    </Menu3dots.Button>
                                    <Menu3dots.Items>
                                        <>
                                            {handleEdit && (
                                                <div className="divide-y border-t-[1px] border-gray-700 py-1">
                                                    <Menu3dots.Item
                                                        onClick={() =>
                                                            handleEdit
                                                                ? handleEdit(
                                                                      item.id,
                                                                      item
                                                                  )
                                                                : {}
                                                        }
                                                    >
                                                        <PencilAltIcon className="w-5 h-5 mr-3" />
                                                        {t(
                                                            'list.employee.edit'
                                                        )}
                                                    </Menu3dots.Item>
                                                </div>
                                            )}
                                            <div className="divide-y divide-gray-200 py-1">
                                                <Menu3dots.Item
                                                    as="link"
                                                    to={`/user/employee/${item.id}`}
                                                    state={{
                                                        state: { id: item.id },
                                                    }}
                                                >
                                                    <PresentationChartBarIcon className="w-5 h-5 mr-3" />
                                                    {t('list.employee.stats')}
                                                </Menu3dots.Item>
                                            </div>
                                            <div className="divide-y border-t-[1px] border-gray-700 divide-gray-200 py-1">
                                                <Menu3dots.Item
                                                    disabled={!item.active}
                                                    onClick={() =>
                                                        handleToggle(
                                                            item.id,
                                                            false
                                                        )
                                                    }
                                                >
                                                    <TrashIcon className="w-5 h-5 mr-3" />
                                                    {t(
                                                        'list.employee.deactivate'
                                                    )}
                                                </Menu3dots.Item>
                                            </div>
                                        </>
                                    </Menu3dots.Items>
                                </>
                            )}
                        </Menu3dots>
                    </td>
                </Tr>
            ))}
        </Tbody>
    )
}

export default EmployeeList
