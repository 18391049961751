import React, { useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { useUsedComplaintsQuery } from 'api'
import { listReducer } from 'helpers'
import PageLayout from 'components/PageLayout'
import UsedComplaintCodesList from 'components/UsedComplaintCodesList'

import type {
    UsedComplaintCodesListFilterState,
    UsedComplaintCodesListFilterAction,
    ListFilterAction,
} from '../types'
import { useModal } from 'hooks'
import ExportModal from 'components/ExportModal'
import { usePolicy } from 'hooks/usePolicy'

const filterReducer = (
    state: UsedComplaintCodesListFilterState,
    action: UsedComplaintCodesListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            length: DEFAULT_LIST_PER_PAGE,
            page: 1,
        }
    }

    return listReducer(
        state,
        action as ListFilterAction
    ) as UsedComplaintCodesListFilterState
}

export default function UsedComplaintCodesListView() {
    const { t } = useTranslation()

    const exportModal = useModal<UsedComplaintCodesListFilterState>()

    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        length: DEFAULT_LIST_PER_PAGE,
        sort_by: 'id',
        sort_direction: 'desc',
        page: 1,
    })

    const filterCount = 0

    const apiFilters = useMemo(() => {
        return {
            search_global: filters.search_global || undefined,
            ...filters,
        }
    }, [filters])

    const usedComplaintCodesQuery = useUsedComplaintsQuery(apiFilters)

    const policy = usePolicy()

    if (!policy.isAdmin() && !policy.isServiceman()) {
        return <div>Forbidden</div>
    }

    return (
        <PageLayout
            title={t('list.used_complaint.title')}
            breadCrumbs={[
                {
                    name: t('bread.complaint_codes'),
                    url: '/complaint-codes',
                },
                {
                    name: t('bread.used_complaint_codes'),
                },
            ]}
            searchBarAction={(value) =>
                dispatchFilterAction({
                    type: 'setFilterQuery',
                    payload: value,
                })
            }
            actions={<></>}
        >
            <>
                <UsedComplaintCodesList
                    usedComplaintCodesQuery={usedComplaintCodesQuery}
                    filters={filters}
                    filterCount={filterCount}
                    filterAction={dispatchFilterAction}
                />
                {exportModal.isOpen && <ExportModal modal={exportModal} />}
            </>
        </PageLayout>
    )
}
