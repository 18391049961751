import { useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import {
    DEFAULT_LIST_PER_PAGE,
    QUERY_CACHE_PLOTTER_TYPES_KEY,
} from '../constants/index'
import { usePlotterTypeQuery } from '../api'
import { listReducer } from '../helpers'
import { Button } from '../components/ui'
import PageLayout from '../components/PageLayout'
import PlotterTypeList from '../components/PlotterTypeList'

import type {
    PlotterTypeListFilterState,
    PlotterTypeListFilterAction,
    ListFilterAction,
    FormSubmitFn,
    PlotterTypeForm,
} from '../types'
import ConfirmModal from '../components/ConfirmModal'
import { useDeletePlotterForm } from '../hooks/forms/useDeletePlotterForm'
import { useFlashMessage, useModal } from '../hooks'
import { PlotterTypeResponse } from '../api/types'
import { usePolicy } from '../hooks/usePolicy'
import PlotterTypeModal from 'components/PlotterTypeModal'
import {
    useCreatePlotterType,
    useUpdatePlotterType,
} from 'api/endpoints/plotter-types'

const filterReducer = (
    state: PlotterTypeListFilterState,
    action: PlotterTypeListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            page: 1,
        }
    }

    return listReducer(
        state,
        action as ListFilterAction
    ) as PlotterTypeListFilterState
}

export default function PlotterTypeListView() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const createPlotterTypeModal = useModal()
    const updatePlotterTypeModal = useModal<{
        id: number
        payload: PlotterTypeResponse
    }>()
    const createPlotterType = useCreatePlotterType()
    const updatePlotterType = useUpdatePlotterType()

    const handleSubmit: FormSubmitFn<PlotterTypeForm> = (
        values,
        formikHelpers
    ) => {
        createPlotterType.mutate(
            {
                data: {
                    name: values.name,
                    description: values.description,
                    size: values.size,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.plotter_type.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PLOTTER_TYPES_KEY,
                        'index',
                    ])
                    createPlotterTypeModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleSubmitEdit: FormSubmitFn<PlotterTypeForm> = (
        values,
        formikHelpers
    ) => {
        updatePlotterType.mutate(
            {
                id: updatePlotterTypeModal.getState()!.id,
                data: {
                    name: values.name,
                    description: values.description,
                    size: values.size,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.plotter_type.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PLOTTER_TYPES_KEY,
                        'index',
                    ])
                    updatePlotterTypeModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const deletePlotterForm = useDeletePlotterForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_PLOTTER_TYPES_KEY, 'index'])
    })

    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        sort_by: 'id',
        sort_direction: 'desc',
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            status: undefined,
            search_global: filters.search_global || undefined,
        }
    }, [filters])

    const plotterTypesQuery = usePlotterTypeQuery(apiFilters)

    const policy = usePolicy()

    if (!policy.isAdmin()) {
        return <div>Forbidden</div>
    }

    return (
        <PageLayout
            title={t('plotter_type.list.title')}
            searchBarAction={(value) =>
                dispatchFilterAction({
                    type: 'setFilterQuery',
                    payload: value,
                })
            }
            actions={
                <>
                    <span className="ml-2">
                        <Button onClick={createPlotterTypeModal.openModal}>
                            {t('form.add')}
                        </Button>
                    </span>
                </>
            }
        >
            <>
                <PlotterTypeList
                    plotterTypesQuery={plotterTypesQuery}
                    filters={filters}
                    filterCount={filters.search_global ? 1 : 0}
                    handleRemove={
                        policy.isAdmin()
                            ? (id) => {
                                  deletePlotterForm.modal.setState(id)
                                  deletePlotterForm.modal.openModal()
                              }
                            : undefined
                    }
                    handleEdit={
                        policy.isAdmin()
                            ? (id, payload) => {
                                  updatePlotterTypeModal.setState({
                                      id,
                                      payload,
                                  })
                                  updatePlotterTypeModal.openModal()
                              }
                            : undefined
                    }
                    filterAction={dispatchFilterAction}
                />
                <PlotterTypeModal
                    modal={createPlotterTypeModal}
                    onSubmit={handleSubmit}
                />
                <PlotterTypeModal
                    modal={updatePlotterTypeModal}
                    plotterTypeData={updatePlotterTypeModal.getState()?.payload}
                    onSubmit={handleSubmitEdit}
                />
                <ConfirmModal
                    modal={deletePlotterForm.modal}
                    title={t('delete.plotter_type.title')}
                    description={t('delete.plotter_type.description')}
                    actionLabel={t('action.delete')}
                    onSubmit={deletePlotterForm.form.submitForm}
                    isSubmitting={deletePlotterForm.form.isSubmitting}
                />
            </>
        </PageLayout>
    )
}
