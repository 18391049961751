import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useQueryClient } from 'react-query'

import { QUERY_CACHE_PLOTTER_TYPES_KEY } from '../../constants/index'
import { useUpdatePlotterTypeVideo } from '../../api'
import { useFlashMessage, useModal } from '..'

export function useVideoActiveForm(typeId: number | string | undefined) {
    const { t } = useTranslation()

    const flashMessage = useFlashMessage()
    const queryClient = useQueryClient()

    const updateVideoQuery = useUpdatePlotterTypeVideo({
        onSuccess: async () => {},
    })
    const confirmModal = useModal<number>()

    const form = useFormik({
        initialValues: {
            active: false,
        },
        onSubmit: (values, formikHelpers) => {
            updateVideoQuery.mutate(
                {
                    id: confirmModal.getState()!,
                    data: {
                        active: values.active,
                    },
                },
                {
                    onSuccess: async () => {
                        await queryClient.invalidateQueries([
                            QUERY_CACHE_PLOTTER_TYPES_KEY,
                            typeId,
                        ])
                        confirmModal.closeModal()
                        flashMessage({
                            type: 'success',
                            content: t('info.plotter_type_video.updated'),
                        })
                        formikHelpers.setSubmitting(false)
                    },
                    onError: (error) => {
                        formikHelpers.setErrors(error.errors)
                        formikHelpers.setSubmitting(false)
                    },
                }
            )
        },
    })

    return {
        modal: confirmModal,
        form: form,
    }
}
