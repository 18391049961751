import React from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import { getCxFromStyles } from '../helpers'
import Modal from './Modal'
import { Button, Label } from './ui'
import { UploaderState } from './FileUploader'
import { TextField, CounterField, FileUploaderField } from './forms'

import type { FormSubmitFn, IModal, BrandForm } from '../types'

import styles from './Modal.module.scss'
import { BrandResponse } from '../api/types'

export type BrandModalProps = {
    modal: IModal
    brandData?: BrandResponse
    onSubmit: FormSubmitFn<BrandForm>
}

export default function BrandModal({
    modal,
    brandData,
    onSubmit,
}: BrandModalProps) {
    const cx = getCxFromStyles(styles)
    const { t } = useTranslation()

    const initialValues: BrandForm = brandData
        ? {
              name: brandData.name,
              logo: 0,
              order: brandData.order,
          }
        : {
              name: '',
              logo: 0,
              order: 0,
          }

    return (
        <Modal modal={modal} type="default">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg pb-6 leading-6 font-medium text-white text-center"
                >
                    {brandData
                        ? t('form.brand.edit_title')
                        : t('form.brand.title')}
                </DialogTitle>
                <Formik<BrandForm>
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className="px-2 pb-2">
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>{t('form.brand.field.name')}</Label>
                                    <TextField name="name" />
                                </div>
                                <div className="col-span-1">
                                    <Label>{t('form.brand.field.order')}</Label>
                                    <CounterField name="order" />
                                </div>
                            </div>
                            <div className="mr-auto max-w-3xl grid grid-cols-1 gap-4 pb-4">
                                <FileUploaderField
                                    name="logo"
                                    fileId={values.logo}
                                    acceptFormats={{ 'image/png': ['.png'] }}
                                    isMultiple={false}
                                    handleUploadSuccess={(
                                        state: UploaderState
                                    ) =>
                                        setFieldValue(
                                            'logo',
                                            state.addedFile.id
                                        )
                                    }
                                    handleRemoveFile={() =>
                                        setFieldValue('logo', 0)
                                    }
                                />
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    {t('form.save')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
