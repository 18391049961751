import React from 'react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Button, Label } from './ui'
import { DropdownField } from './forms'

import type { FormSubmitFn, GenerateServicePinForm } from '../types'

import { useShortTimespansDropdown } from '../hooks'

type ServicePinProps = {
    onSubmit: FormSubmitFn<GenerateServicePinForm>
    onDisableServicePin: FormSubmitFn<{}>
    readonly?: boolean
    servicePin?: string
    expire_at?: string
}

export default function ServicePin({
    onSubmit,
    onDisableServicePin,
    servicePin,
    expire_at,
    readonly = false,
}: ServicePinProps) {
    const { t } = useTranslation()

    const timespans = useShortTimespansDropdown()

    return (
        <div>
            <Formik<GenerateServicePinForm>
                initialValues={{}}
                onSubmit={onDisableServicePin}
            >
                {({ isSubmitting }) => (
                    <Form className="my-12">
                        <div className="my-6 flex gap-4 flex-wrap sm:block">
                            <h2 className="text-xl leading-7 font-medium sm:pb-4 flex items-center">
                                {t('form.service_pin.your_pin')}
                            </h2>
                            <div className="text-4xl leading-10 font-medium sm:mr-20 float-none sm:float-left">
                                <div>{servicePin ?? '---- ----'}</div>
                                {expire_at ? (
                                    <div className="text-sm leading-5 font-medium">
                                        <span className="text-gray-400 pr-1">
                                            {t('form.service_pin.expire_at')}
                                        </span>
                                        <span>
                                            {moment
                                                .utc(expire_at)
                                                .local()
                                                .format('DD-MM-YYYY HH:mm')}
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="flex-grow basis-full">
                                <Button
                                    type="submit"
                                    variant="danger"
                                    loading={isSubmitting}
                                    disabled={!servicePin}
                                >
                                    {t('form.disable_pin')}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Formik<GenerateServicePinForm>
                initialValues={{}}
                onSubmit={onSubmit}
            >
                {({ values, setFieldValue, isSubmitting }) => (
                    <Form>
                        <div className="rounded-lg bg-gray-800 p-6 mt-6">
                            <div className="mb-6 grid grid-cols-1 gap-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.service_pin.field.timespan')}
                                    </Label>
                                    <DropdownField
                                        name="validity_time"
                                        value={values.validity_time}
                                        items={timespans}
                                        disabled={readonly}
                                        anchor="top"
                                        onChange={(value) =>
                                            setFieldValue(
                                                'validity_time',
                                                value
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="">
                                <Button
                                    type="submit"
                                    variant="primary"
                                    loading={isSubmitting}
                                >
                                    {t('form.generate_pin')}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
