import React from 'react'
import { useTranslation } from 'react-i18next'

import { Label, Dropdown } from './ui'

import {
    DropdownItem,
    EmployeeListFilterAction,
    EmployeeListFilterState,
} from '../types'
import { useUserStatuses } from '../hooks/useUserStatuses'

const EmployeeListFilters: React.FC<{
    filters: EmployeeListFilterState
    filterAction: React.Dispatch<EmployeeListFilterAction>
}> = ({ filters, filterAction }) => {
    const { t } = useTranslation()

    const employeeStatuses = useUserStatuses()

    const ALL_ITEM: DropdownItem = { id: '', name: t('dictionary.all') }

    return (
        <div className="mt-3 px-4 py-3 mb-4 bg-gray-800 rounded-lg">
            <div className="grid grid-cols-3 gap-4">
                <div>
                    <Label>{t('filters.status')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(employeeStatuses)}
                            value={
                                employeeStatuses.find(
                                    (item) => item.id === filters.status?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterStatus',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeListFilters
