import { useQuery, useMutation } from 'react-query'

import client from '../client'
import { QUERY_CACHE_EMPLOYEES_KEY } from '../../constants/index'

import type {
    UseQueryOptions,
    UseQueryResult,
    UseMutationOptions,
} from 'react-query'
import type {
    ResponseData,
    ResponseError,
    EmployeeResponse,
    UpdateEmployeeVariables,
    CreateEmployeeVariables,
    ResponseList,
    EmployeeListResponse,
} from '../types'
import qs from 'qs'

export const getEmployeeById = (
    id: string
): Promise<ResponseData<EmployeeResponse>> => client.get(`/employees/${id}`)

export const useEmployeeByIdQuery = <TData = ResponseData<EmployeeResponse>>(
    id: string,
    queryOptions?: UseQueryOptions<
        ResponseData<EmployeeResponse>,
        ResponseError,
        TData,
        [string, string]
    >
): UseQueryResult<TData, ResponseError> =>
    useQuery(
        [QUERY_CACHE_EMPLOYEES_KEY, id],
        () => getEmployeeById(id),
        queryOptions
    )

export const getEmployees = <T>(filters?: {}): Promise<T> =>
    client.get('/employees' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useEmployeesQuery = <T = ResponseList<EmployeeListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_EMPLOYEES_KEY, 'index', filters],
        () => getEmployees<T>({ ...filters }),
        options
    )

const createEmployee = ({
    data,
}: CreateEmployeeVariables): Promise<ResponseData<EmployeeResponse>> =>
    client.post('/employees', data)

export const useCreateEmployee = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<EmployeeResponse>,
            ResponseError,
            CreateEmployeeVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => createEmployee(data), options)

const updateEmployee = ({
    id,
    data,
}: UpdateEmployeeVariables): Promise<ResponseData<EmployeeResponse>> =>
    client.patch(`/employees/${id}`, data)

export const useUpdateEmployee = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<EmployeeResponse>,
            ResponseError,
            UpdateEmployeeVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => updateEmployee(data), options)
