import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Spinner } from './ui'
import TableFooter from './TableFooter'
import { Button3dots } from './ui'
import Menu3dots from './Menu3dots'
import {
    TableHeader,
    TableContainer,
    Table,
    Tbody,
    Tempty,
    Tr,
} from './ui/List'

import { PencilAltIcon } from '@heroicons/react/solid'
import { TrashIcon } from '@heroicons/react/outline'

import type { UseQueryResult } from 'react-query'
import type {
    BladeTypeListFilterState,
    BladeTypeListFilterAction,
} from '../types'
import type {
    ResponseList,
    BladeTypeListResponse,
    BladeTypeResponse,
    ProtectionFilmTypeListResponse,
} from '../api/types'
import SortButton from './ui/SortButton'

type BladeListProps = {
    bladeTypesQuery: UseQueryResult<ResponseList<BladeTypeListResponse>>
    filters: BladeTypeListFilterState
    filterCount: number
    filterAction?: React.Dispatch<BladeTypeListFilterAction>
    handleRemove?: (id: number) => void
    handleEdit?: (id: number, payload: BladeTypeResponse) => void
    filmTypes: ProtectionFilmTypeListResponse
}

type TableProps = {
    data: BladeTypeListResponse
    additionalColumns: { id: number }[]
    handleRemove?: (id: number) => void
    handleEdit?: (id: number, payload: BladeTypeResponse) => void
}

const BladeTypeList: React.FC<BladeListProps> = ({
    bladeTypesQuery,
    filmTypes,
    filters,
    filterCount,
    filterAction,
    handleRemove,
    handleEdit,
}) => {
    const { t } = useTranslation()

    const additionalColumns = filmTypes
        .slice(0, 5)
        .map((filmType) => ({ id: filmType.id, name: filmType.name }))

    return (
        <TableContainer>
            <Table>
                <TableHeader>
                    <th
                        scope="col"
                        className="w-[90px] px-6 py-4 text-left tracking-wider"
                    >
                        {filterAction ? (
                            <SortButton
                                disabled={bladeTypesQuery.isLoading}
                                filters={filters}
                                action={filterAction}
                                name="id"
                            >
                                <div className="text-left tracking-wider uppercase">
                                    {t('list.blade_type.id')}
                                </div>
                            </SortButton>
                        ) : (
                            t('list.blade_type.id')
                        )}
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        {filterAction ? (
                            <SortButton
                                disabled={bladeTypesQuery.isLoading}
                                filters={filters}
                                action={filterAction}
                                name="name"
                            >
                                <div className="text-left tracking-wider uppercase">
                                    {t('list.blade_type.name')}
                                </div>
                            </SortButton>
                        ) : (
                            t('list.blade_type.name')
                        )}
                    </th>

                    {additionalColumns.map(({ name }) => (
                        <th
                            scope="col"
                            className="w-auto px-6 py-4 text-left tracking-wider"
                        >
                            {name}
                        </th>
                    ))}
                    <th
                        scope="col"
                        className="w-auto min-w-[80px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        {t('list.blade_type.count')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[65px] px-6 py-4 text-left tracking-wider"
                    ></th>
                </TableHeader>
                {bladeTypesQuery.isSuccess && bladeTypesQuery.isFetched && (
                    <>
                        {bladeTypesQuery.data.meta.total > 0 && (
                            <TableBody
                                data={bladeTypesQuery.data.data}
                                additionalColumns={additionalColumns}
                                handleRemove={handleRemove}
                                handleEdit={handleEdit}
                            />
                        )}
                    </>
                )}
                {bladeTypesQuery.data?.meta.total === 0 && (
                    <Tbody>
                        <Tr key={-1} index={0}>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4 relative h-12">
                                {filterCount === 0 && (
                                    <Tempty>
                                        {t('list.blade_type.empty_list')}
                                    </Tempty>
                                )}
                                {filterCount > 0 && (
                                    <Tempty>
                                        {t('list.blade_type.empty_search')}
                                    </Tempty>
                                )}
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            {additionalColumns.map(() => (
                                <td className="px-6 py-4">
                                    <span></span>
                                </td>
                            ))}
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                        </Tr>
                    </Tbody>
                )}
            </Table>
            {bladeTypesQuery.isLoading && (
                <div className="divide-y divide-gray-700 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (_, index) => (
                            <div
                                key={index}
                                className={classNames('relative h-[68px]', {
                                    'bg-gray-800': index % 2,
                                    'bg-background': !(index % 2),
                                })}
                            >
                                {index === 4 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            {filterAction && (
                <TableFooter
                    meta={bladeTypesQuery.data?.meta}
                    filterAction={filterAction}
                />
            )}
        </TableContainer>
    )
}

const TableBody: React.FC<TableProps> = ({
    data,
    additionalColumns,
    handleRemove,
    handleEdit,
}) => {
    const { t } = useTranslation()

    const isMutating = false
    return (
        <Tbody>
            {data.map((item, index) => (
                <Tr key={item.id} index={index}>
                    <td className="px-6 py-4">
                        <span>{item.id}</span>
                    </td>
                    <td className="px-6 py-4">{item.name ?? '-'}</td>
                    <>
                        {additionalColumns.map(({ id }) => (
                            <td className="px-6 py-4">
                                {item.protection_film_types.find(
                                    (v) => v.protection_film_type_id === id
                                )?.cuts_number ?? '-'}
                            </td>
                        ))}
                    </>
                    <td className="px-6 py-4">{item.cutters_count ?? '-'}</td>
                    <td className="px-6 py-4 text-right">
                        <Menu3dots>
                            {({ open }) => (
                                <>
                                    <Menu3dots.Button
                                        as={
                                            isMutating ? React.Fragment : 'span'
                                        }
                                    >
                                        <Button3dots
                                            open={open}
                                            loading={isMutating}
                                        />
                                    </Menu3dots.Button>
                                    <Menu3dots.Items>
                                        <>
                                            <div className="divide-y border-t-[1px] border-gray-700 py-1">
                                                <Menu3dots.Item
                                                    disabled={!handleEdit}
                                                    onClick={() =>
                                                        handleEdit
                                                            ? handleEdit(
                                                                  item.id,
                                                                  item
                                                              )
                                                            : {}
                                                    }
                                                >
                                                    <PencilAltIcon className="w-5 h-5 mr-3" />
                                                    {t('list.blade_type.edit')}
                                                </Menu3dots.Item>
                                            </div>

                                            <div className="divide-y border-t-[1px] border-gray-700 divide-gray-200 py-1">
                                                <Menu3dots.Item
                                                    disabled={!handleRemove}
                                                    onClick={() =>
                                                        handleRemove
                                                            ? handleRemove(
                                                                  item.id
                                                              )
                                                            : {}
                                                    }
                                                >
                                                    <TrashIcon className="w-5 h-5 mr-3" />
                                                    {t(
                                                        'list.blade_type.delete'
                                                    )}
                                                </Menu3dots.Item>
                                            </div>
                                        </>
                                    </Menu3dots.Items>
                                </>
                            )}
                        </Menu3dots>
                    </td>
                </Tr>
            ))}
        </Tbody>
    )
}

export default BladeTypeList
