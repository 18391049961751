import { useQuery, useMutation } from 'react-query'
import qs from 'qs'
import type { UseMutationOptions } from 'react-query'

import client from '../client'
import { QUERY_CACHE_PLOTTER_TYPE_VIDEOS_KEY } from '../../constants/index'

import type { UseQueryOptions, UseQueryResult } from 'react-query'
import type {
    ResponseList,
    ResponseError,
    DeleteVariables,
    PlotterTypeVideoListResponse,
    PlotterTypeVideoResponse,
    ResponseData,
    CreatePlotterTypeVideoVariables,
    UpdatePlotterTypeVideoVariables,
} from '../types'

export const getPlotterTypeVideo = <T>(filters?: {}): Promise<T> =>
    client.get('/tutorials' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const usePlotterTypeVideoQuery = <
    T = ResponseList<PlotterTypeVideoListResponse>,
>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_PLOTTER_TYPE_VIDEOS_KEY, 'index', filters],
        () => getPlotterTypeVideo<T>({ ...filters }),
        options
    )

const createPlotterTypeVideo = ({
    data,
}: CreatePlotterTypeVideoVariables): Promise<
    ResponseData<PlotterTypeVideoResponse>
> => client.post('/tutorials', data)

export const useCreatePlotterTypeVideo = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<PlotterTypeVideoResponse>,
            ResponseError,
            CreatePlotterTypeVideoVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => createPlotterTypeVideo(data), options)

const updatePlotterTypeVideo = ({
    id,
    data,
}: UpdatePlotterTypeVideoVariables): Promise<
    ResponseData<PlotterTypeVideoResponse>
> => client.patch(`/tutorials/${id}`, data)

export const useUpdatePlotterTypeVideo = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<PlotterTypeVideoResponse>,
            ResponseError,
            UpdatePlotterTypeVideoVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => updatePlotterTypeVideo(data), options)

const deletePlotterTypeVideo = ({
    id,
}: DeleteVariables): Promise<ResponseData<void>> =>
    client.delete(`/tutorials/${id}`)

export const useDeletePlotterTypeVideo = (
    options?: Omit<
        UseMutationOptions<ResponseData<void>, ResponseError, DeleteVariables>,
        'mutationFn'
    >
) => useMutation((data) => deletePlotterTypeVideo(data), options)
