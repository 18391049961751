import React from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { DialogTitle } from '@headlessui/react'

import { getCxFromStyles } from '../helpers'
import Modal from './Modal'
import { Button } from './ui'

import type { IModal } from '../types'

import styles from './Modal.module.scss'

export type ConfirmModalProps = {
    modal: IModal
    title: string
    description: string
    actionLabel: string
    isSubmitting: boolean
    onSubmit: () => void
}

export default function ConfirmModal({
    modal,
    title,
    description,
    actionLabel,
    isSubmitting,
    onSubmit,
}: ConfirmModalProps) {
    const cx = getCxFromStyles(styles)

    return (
        <Modal modal={modal} type="warning">
            <div>
                <div className="flex flex-col items-center">
                    <div className="w-12 h-12 p-3 bg-red-100 rounded-3xl justify-center items-center inline-flex mb-6">
                        <ExclamationIcon className="w-6 h-6 text-red-600 relative flex-col justify-start items-start flex" />
                    </div>
                    <DialogTitle
                        as="h3"
                        className="text-lg leading-6 font-medium text-white text-center"
                    >
                        {title}
                    </DialogTitle>
                </div>
                <div className="px-2 pb-2">
                    <div className="py-6 text-gray-100 text-sm leading-5 font-normal text-center">
                        {description}
                    </div>
                    <div className={cx('action-container')}>
                        <Button
                            tabIndex={-1}
                            variant="secondary"
                            type="button"
                            onClick={modal.closeModal}
                        >
                            Anuluj
                        </Button>
                        <Button
                            tabIndex={-1}
                            variant="primary"
                            onClick={onSubmit}
                            loading={isSubmitting}
                        >
                            {actionLabel}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
