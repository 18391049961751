import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { useUpdateRecipient } from '../../api'
import { useFlashMessage, useModal } from '..'

export function useRecipientForm(onSuccess?: () => void) {
    const { t } = useTranslation()

    const flashMessage = useFlashMessage()

    const updateRecipient = useUpdateRecipient({
        onSuccess: async () => {},
    })
    const confirmModal = useModal<number>()

    const form = useFormik<{ email?: string }>({
        initialValues: {
            email: '',
        },
        onSubmit: (values, formikHelpers) => {
            updateRecipient.mutate(
                {
                    id: confirmModal.getState()!,
                    data: {
                        email: values.email,
                    },
                },
                {
                    onSuccess: async () => {
                        confirmModal.closeModal()
                        flashMessage({
                            type: 'success',
                            content: t('info.recipient.updated'),
                        })
                        formikHelpers.setSubmitting(false)
                        onSuccess && onSuccess()
                    },
                    onError: (error) => {
                        formikHelpers.setErrors(error.errors)
                        formikHelpers.setSubmitting(false)
                    },
                }
            )
        },
    })

    return {
        modal: confirmModal,
        form: form,
    }
}
