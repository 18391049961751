import React from 'react'
import { Form, FormikErrors } from 'formik'
import { useTranslation } from 'react-i18next'

import type { PlotterTypeForm } from '../types'

import {
    RadioGroupField,
    RadioGroupHorizontalGrid,
} from './forms/RadioGroupField'
import { usePolicy } from '../hooks/usePolicy'
import { usePlotterSizes } from 'hooks/usePlotterSizes'

type PlotterTypeVideosProps = {
    values: PlotterTypeForm
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => Promise<void | FormikErrors<PlotterTypeForm>>
    isSubmitting: boolean
    readonly?: boolean
}

export default function PlotterTypeVideosForm({
    readonly = false,
}: PlotterTypeVideosProps) {
    const policy = usePolicy()
    const { t } = useTranslation()

    const sizes = usePlotterSizes()

    return (
        <Form>
            <div className="mt-8 pb-8">
                {policy.isAdmin() && (
                    <>
                        <div className="border-t border-gray-600 my-5"></div>
                        <div>
                            <div className="flex flex-col">
                                <span className="inline-block text-lg leading-8 font-medium">
                                    {t('form.plotter_type.field.type')}
                                </span>
                                <span className="pb-5 text-sm leading-5 font-normal">
                                    {t('form.plotter_type.field.type.sub')}
                                </span>
                            </div>
                            <RadioGroupField<string>
                                name="size"
                                Grid={RadioGroupHorizontalGrid}
                                disabled={readonly}
                                options={sizes
                                    .map((val) => ({
                                        value: val.value,
                                        name: val.name,
                                    }))
                                    .reverse()}
                            />
                            <div className="border-t border-gray-600 my-1"></div>
                        </div>
                    </>
                )}
            </div>
        </Form>
    )
}
