import { useCurrentUser } from './useCurrentUser'
import { useUserRoles } from './useUserRoles'

interface iPolicy {
    isAdmin: () => boolean
    isSupervisor: () => boolean
    isUser: () => boolean
    isTrainer: () => boolean
    isServiceman: () => boolean
    isEditor: () => boolean
}

export const usePolicy = (): iPolicy => {
    const user = useCurrentUser()
    const roles = useUserRoles()

    if (!user.isSuccess) {
        return {
            isAdmin: () => false,
            isSupervisor: () => false,
            isUser: () => true,
            isTrainer: () => true,
            isServiceman: () => true,
            isEditor: () => true,
        }
    }

    return {
        isAdmin: () => user.data?.data.role?.id === roles.admin.id,
        isSupervisor: () => user.data?.data.role?.id === roles.supervisor.id,
        isUser: () => user.data?.data.role?.id === roles.user.id,
        isTrainer: () => user.data?.data.role?.id === roles.trainer.id,
        isServiceman: () => user.data?.data.role?.id === roles.serviceman.id,
        isEditor: () => user.data?.data.role?.id === roles.editor.id,
    }
}
