import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserQuery, usePlotterQuery, useCountriesQuery } from 'api'
import { useProtectionFilmTypeQuery } from 'api'
import { useUserRoles } from './useUserRoles'

import { DEVICE_TYPES, FOIL_SIZES } from '../constants'
import { DropdownItem } from '../types'
import { TIMESPANS } from 'constants/timespans'

export const useAllItem = () => {
    const { t } = useTranslation()
    const ALL_ITEM: DropdownItem = useMemo(
        () => ({ id: '', name: t('dictionary.all') }),
        [t]
    )
    return ALL_ITEM
}

export const useSupervisorsDropdown = (): DropdownItem[] => {
    const userRoles = useUserRoles()
    const usersToAssign = useUserQuery({ roles: [userRoles.supervisor.id] })

    const userDropdownValues = useMemo(
        () =>
            usersToAssign.data?.data.map((user) => ({
                name: `${user.first_name} ${user.last_name}`,
                description: user.name,
                id: user.id,
            })) || [],
        [usersToAssign]
    )

    return userDropdownValues
}

export const useUsersDropdown = (): DropdownItem[] => {
    const userRoles = useUserRoles()
    const usersToAssign = useUserQuery({ roles: [userRoles.user.id] })

    const userDropdownValues = useMemo(
        () =>
            usersToAssign.data?.data.map((user) => ({
                name: user.name
                    ? user.name
                    : `${user.first_name} ${user.last_name}`,
                description: user.email,
                id: user.id,
            })) || [],
        [usersToAssign]
    )

    return userDropdownValues
}

export const usePlottersDropdown = (): DropdownItem[] | undefined => {
    const plottersToAssign = usePlotterQuery({ assigned: false })

    const plotterDropdownValues = useMemo(
        () =>
            plottersToAssign.data?.data.map((plotter) => ({
                name: plotter.name,
                id: plotter.id,
            })),
        [plottersToAssign]
    )

    return plotterDropdownValues
}

export const useProtectionFilmTypeDropdown = () => {
    const protectionFilmTypes = useProtectionFilmTypeQuery()

    const protectionFilmTypesDropdownValues = useMemo(
        () =>
            protectionFilmTypes.data?.data.map((protectionFilmType) => ({
                name: protectionFilmType.name,
                id: protectionFilmType.id,
                pressure: protectionFilmType.default_pressure,
                speed: protectionFilmType.default_speed,
            })),
        [protectionFilmTypes]
    )

    return protectionFilmTypesDropdownValues
}

export const useSpeedsDropdown = (): {
    default: DropdownItem
    dropdownItems: DropdownItem[]
} => {
    return {
        default: { name: '100', id: 100 },
        dropdownItems: Array.from(
            Array(15)
                .keys()
                .map((i) => {
                    const val = (i + 2) * 50
                    return {
                        name: val.toString(),
                        id: val,
                    }
                })
        ),
    }
}

export const usePressuresDropdown = (): {
    default: DropdownItem
    dropdownItems: DropdownItem[]
} => {
    return {
        default: { name: '400', id: 400 },
        dropdownItems: Array.from(
            Array(19)
                .keys()
                .map((i) => {
                    const val = (i + 2) * 50
                    return {
                        name: val.toString(),
                        id: val,
                    }
                })
        ),
    }
}

export const useTransparentsDropdown = (): {
    protectionFilmTransparent: DropdownItem
    protectionFilmNotTransparent: DropdownItem
    dropdownItems: DropdownItem[]
} => {
    const { t } = useTranslation()
    const protectionFilmTransparent = {
        id: 1,
        name: t('protection_films_transparent.transparent'),
    }
    const protectionFilmNotTransparent = {
        id: 0,
        name: t('protection_films_transparent.not_transparent'),
    }

    return {
        protectionFilmTransparent,
        protectionFilmNotTransparent,
        dropdownItems: [
            protectionFilmTransparent,
            protectionFilmNotTransparent,
        ],
    }
}

export const useBladeTypesDropdown = (): DropdownItem[] => {
    const { t } = useTranslation()

    const bladeTypeFilm = {
        id: 'film',
        name: t('blade_type.film'),
    }

    const bladeTypeGlass = {
        id: 'glass',
        name: t('blade_type.glass'),
    }

    const bladeTypeLaser = {
        id: 'laser',
        name: t('blade_type.laser'),
    }

    const bladeTypePlasma = {
        id: 'plasma',
        name: t('blade_type.plasma'),
    }

    return [bladeTypeFilm, bladeTypeGlass, bladeTypeLaser, bladeTypePlasma]
}

export const useCountriesDropdown = (): DropdownItem[] => {
    const countriesQuery = useCountriesQuery()

    const countriesDropdownValues = useMemo(
        () =>
            countriesQuery.data?.data.map((country) => ({
                name: country.name,
                id: country.id,
            })) || [],
        [countriesQuery.data?.data]
    )

    return countriesDropdownValues
}

export const useFoilTypesDropdown = (): DropdownItem[] => {
    const foilsQuery = useProtectionFilmTypeQuery()
    const foilTypesDropdownValues = useMemo(
        () =>
            foilsQuery.data?.data.map((foilType) => ({
                name: foilType.name,
                id: foilType.id,
            })) || [],
        [foilsQuery]
    )

    return foilTypesDropdownValues
}

export const useFoilSizesDropdown = (): DropdownItem[] => {
    const { t } = useTranslation()
    const foilSizesDropdownValues = useMemo(
        () =>
            FOIL_SIZES.map((foilSize) => ({
                name: t(`protection_films_sizes.${foilSize}`),
                id: foilSize,
            })) || [],
        [t]
    )

    return foilSizesDropdownValues
}

export const useShortTimespansDropdown = (): DropdownItem[] => {
    const { t } = useTranslation()
    const timespansDropdownValues = useMemo(
        () =>
            TIMESPANS.map((timespan) => ({
                name: t(`timespan.${timespan}`),
                id: timespan,
            })) || [],
        [t]
    )

    return timespansDropdownValues
}

export const useTimespansDropdown = (): DropdownItem[] => {
    const { t } = useTranslation()
    const timespansDropdownValues = useMemo(
        () =>
            TIMESPANS.map((timespan) => ({
                name: t(`timespan.${timespan}`),
                id: timespan,
            })) || [],
        [t]
    )

    return timespansDropdownValues
}

export const useDeviceTypesDropdown = (): DropdownItem[] => {
    const { t } = useTranslation()
    const deviceTypesDropdownValues = useMemo(
        () =>
            DEVICE_TYPES.map((deviceType) => ({
                name: t(`device_type.${deviceType}`),
                id: deviceType,
            })) || [],
        [t]
    )

    return deviceTypesDropdownValues
}
