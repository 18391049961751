import { Navigate } from 'react-router-dom'

import { usePolicy } from 'hooks/usePolicy'

const Main = () => {
    const policy = usePolicy()

    if (policy.isServiceman() || policy.isTrainer()) {
        return <Navigate to="/role/user" />
    }

    if (policy.isEditor()) {
        return <Navigate to="/brand" />
    }

    return <Navigate to="/stats" />
}

export default Main
