import React from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import { getCxFromStyles } from '../helpers'
import Modal from './Modal'
import { Button, Label } from './ui'
import { UploaderState } from './FileUploader'
import { DropdownField, FileUploaderField, TextField } from './forms'

import type { FormSubmitFn, IModal, PatternForm } from '../types'

import styles from './Modal.module.scss'
import { PatternResponse } from '../api/types'
import { useFoilSizesDropdown } from 'hooks'
import {
    RadioGroupHorizontalGrid,
    RadioGroupField,
} from './forms/RadioGroupField'

export type PatternModalProps = {
    modal: IModal
    patternData?: PatternResponse
    onSubmit: FormSubmitFn<PatternForm>
}

export default function PatternModal({
    modal,
    patternData,
    onSubmit,
}: PatternModalProps) {
    const cx = getCxFromStyles(styles)
    const { t } = useTranslation()

    const sizes = useFoilSizesDropdown()

    const initialValues: PatternForm = patternData
        ? {
              name: patternData.name,
              transparent: patternData.transparent,
              svg: 0,
              plt: 0,
              size: sizes.find((size) => size.id === patternData.size),
          }
        : {
              name: '',
              transparent: undefined,
              svg: 0,
              plt: 0,
              size: undefined,
          }

    const translucencyValues = [
        {
            value: 1,
            name: t('form.pattern.field.translucent'),
        },
        {
            value: 0,
            name: t('form.pattern.field.matt'),
        },
    ]

    return (
        <Modal modal={modal} type="default">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg pb-6 leading-6 font-medium text-white text-center"
                >
                    {patternData
                        ? t('form.pattern.edit_title')
                        : t('form.pattern.title')}
                </DialogTitle>
                <Formik<PatternForm>
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className="px-2 pb-2">
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.pattern.field.name')}
                                    </Label>
                                    <TextField name="name" />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.pattern.field.size')}
                                    </Label>
                                    <DropdownField
                                        name="size"
                                        value={sizes.find(
                                            (size) =>
                                                size.id === values.size?.id
                                        )}
                                        items={sizes}
                                        onChange={(value) =>
                                            setFieldValue('size', value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t(
                                            'form.pattern.field.translucency_type'
                                        )}
                                    </Label>
                                    <RadioGroupField<number>
                                        name="transparent"
                                        Grid={RadioGroupHorizontalGrid}
                                        options={translucencyValues}
                                    />
                                </div>
                            </div>
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>{t('form.pattern.field.png')}</Label>
                                    <FileUploaderField
                                        name="svg"
                                        fileId={values.svg}
                                        acceptFormats={{
                                            'image/png': ['.png'],
                                        }}
                                        isMultiple={false}
                                        handleUploadSuccess={(
                                            state: UploaderState
                                        ) =>
                                            setFieldValue(
                                                'svg',
                                                state.addedFile.id
                                            )
                                        }
                                        handleRemoveFile={() =>
                                            setFieldValue('svg', 0)
                                        }
                                        addedFile={
                                            !!patternData
                                                ? {
                                                      file: {},
                                                      title: `${patternData.svg_name}.png`,
                                                  }
                                                : undefined
                                        }
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>{t('form.pattern.field.plt')}</Label>
                                    <FileUploaderField
                                        name="plt"
                                        fileId={values.plt}
                                        acceptFormats={{
                                            'application/plt': ['.plt'],
                                        }}
                                        isMultiple={false}
                                        handleUploadSuccess={(
                                            state: UploaderState
                                        ) =>
                                            setFieldValue(
                                                'plt',
                                                state.addedFile.id
                                            )
                                        }
                                        handleRemoveFile={() =>
                                            setFieldValue('plt', 0)
                                        }
                                        addedFile={
                                            !!patternData
                                                ? {
                                                      file: {},
                                                      title: `${patternData.plt_name}.plt`,
                                                  }
                                                : undefined
                                        }
                                    />
                                </div>
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    {t('form.save')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
