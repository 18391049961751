import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Form, Formik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'

import { QUERY_CACHE_PLOTTER_TYPES_KEY } from '../constants/index'
import {
    useGetPlotterTypeByIdQuery,
    useCreatePlotterTypeVideo,
    useUpdatePlotterTypeVideo,
    useUpdatePlotterType,
} from '../api'
import { Button, Loader } from '../components/ui'
import PageLayout from '../components/PageLayout'
import PlotterTypeVideoList from '../components/PlotterTypeVideos'
import ConfirmModal from '../components/ConfirmModal'
import PlotterTypeVideoModal from 'components/PlotterTypeVideoModal'
import PlotterTypeVideosForm from 'components/PlotterTypeVideosForm'

import type {
    PlotterTypeVideoForm,
    FormSubmitFn,
    PlotterTypeForm,
} from '../types'
import { useDeletePlotterTypeVideoForm } from '../hooks/forms/useDeletePlotterTypeVideoForm'
import { useFlashMessage, useModal } from '../hooks'
import { PlotterTypeVideoResponse } from '../api/types'
import { usePolicy } from '../hooks/usePolicy'
import { useVideoActiveForm } from 'hooks/forms/useVideoActiveForm'

export default function PlotterTypeVideoListView() {
    const { t } = useTranslation()
    const { id } = useParams<string>()
    const navigate = useNavigate()

    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const plotterTypeVideoQuery = useGetPlotterTypeByIdQuery(id as string)

    const createPlotterTypeVideoModal = useModal()
    const updatePlotterTypeVideoModal = useModal<{
        id: number
        payload: PlotterTypeVideoResponse
    }>()

    const deletePlotterTypeVideoForm = useDeletePlotterTypeVideoForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_PLOTTER_TYPES_KEY, id])
    })

    const createPlotterTypeVideo = useCreatePlotterTypeVideo()
    const updatePlotterTypeVideo = useUpdatePlotterTypeVideo()
    const updatePlotterType = useUpdatePlotterType()

    const { form: activeVideoForm, modal: activeVideoFormModal } =
        useVideoActiveForm(id)

    const handleSubmit: FormSubmitFn<PlotterTypeVideoForm> = (
        values,
        formikHelpers
    ) => {
        createPlotterTypeVideo.mutate(
            {
                data: {
                    name: values.name,
                    link: values.link,
                    video: values.video || undefined,
                    cutter_type: id,
                    cover: values.cover || undefined,
                    active: true,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.plotter_type_video.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PLOTTER_TYPES_KEY,
                        id,
                    ])
                    createPlotterTypeVideoModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleSubmitEdit: FormSubmitFn<PlotterTypeVideoForm> = (
        values,
        formikHelpers
    ) => {
        updatePlotterTypeVideo.mutate(
            {
                id: updatePlotterTypeVideoModal.getState()!.id,
                data: {
                    name: values.name,
                    link: values.link,
                    video: values.video || undefined,
                    cover: values.cover || undefined,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.plotter_type_video.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PLOTTER_TYPES_KEY,
                        id,
                    ])
                    updatePlotterTypeVideoModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleSubmitTypeEdit: FormSubmitFn<PlotterTypeForm> = (
        values,
        formikHelpers
    ) => {
        updatePlotterType.mutate(
            {
                id: parseInt(id!),
                data: {
                    name: values.name,
                    description: values.description,
                    size: values.size,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.plotter.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PLOTTER_TYPES_KEY,
                        'index',
                    ])
                    navigate('/machine-type')
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const policy = usePolicy()

    if (!policy.isAdmin()) {
        return <div>Forbidden</div>
    }

    if (plotterTypeVideoQuery.isLoading) {
        return <Loader />
    }

    if (plotterTypeVideoQuery.isError) {
        return (
            <div className="text-red-600">
                {plotterTypeVideoQuery.error.message}
            </div>
        )
    }

    if (!plotterTypeVideoQuery.isSuccess) {
        return null
    }

    return (
        <PageLayout
            title={plotterTypeVideoQuery.data.data.name}
            actions={
                <>
                    <span className="ml-2">
                        <Button onClick={createPlotterTypeVideoModal.openModal}>
                            {t('action.plotter_type_video.create')}
                        </Button>
                    </span>
                </>
            }
        >
            <>
                <Formik<PlotterTypeForm>
                    initialValues={{
                        name: plotterTypeVideoQuery.data.data.name,
                        description:
                            plotterTypeVideoQuery.data.data.description,
                        size: plotterTypeVideoQuery.data.data.size,
                    }}
                    onSubmit={handleSubmitTypeEdit}
                >
                    {({ values, setFieldValue, isSubmitting }) => (
                        <>
                            <PlotterTypeVideosForm
                                values={values}
                                setFieldValue={setFieldValue}
                                isSubmitting={isSubmitting}
                            />
                            <PlotterTypeVideoList
                                plotterTypeVideoQuery={
                                    {
                                        data: {
                                            data: plotterTypeVideoQuery.data
                                                .data.tutorials,
                                            meta: {
                                                current_page: 1,
                                                per_page:
                                                    plotterTypeVideoQuery.data
                                                        .data.tutorials.length,
                                                from: 1,
                                                to: 1,
                                                last_page: 1,
                                                total: plotterTypeVideoQuery
                                                    .data.data.tutorials.length,
                                            },
                                        },
                                        isSuccess: true,
                                        isError: false,
                                        isFetched: true,
                                    } as any
                                }
                                filters={{ length: 1 }}
                                filterCount={0}
                                handleEdit={(id, payload) => {
                                    updatePlotterTypeVideoModal.setState({
                                        id,
                                        payload,
                                    })
                                    updatePlotterTypeVideoModal.openModal()
                                }}
                                handleRemove={(id) => {
                                    deletePlotterTypeVideoForm.modal.setState(
                                        id
                                    )
                                    deletePlotterTypeVideoForm.modal.openModal()
                                }}
                                handleToggle={(id, state) => {
                                    activeVideoFormModal.setState(id)
                                    if (state === false) {
                                        activeVideoForm.setValues({
                                            active: false,
                                        })
                                        activeVideoFormModal.openModal()
                                    } else {
                                        activeVideoForm.setValues({
                                            active: true,
                                        })
                                        activeVideoForm.submitForm()
                                    }
                                }}
                                filterAction={() => {}}
                            />
                            <Form>
                                <div className="flex mt-8 pt-5 items-center justify-between">
                                    <div className="flex-1"></div>
                                    <div className="flex-1 flex items-center justify-end ml-3">
                                        <Button
                                            variant="secondary"
                                            as="link"
                                            to="/machine-type"
                                        >
                                            {t('form.cancel')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="ml-3"
                                            loading={isSubmitting}
                                        >
                                            {t('form.save')}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
                <PlotterTypeVideoModal
                    modal={createPlotterTypeVideoModal}
                    onSubmit={handleSubmit}
                />
                <PlotterTypeVideoModal
                    modal={updatePlotterTypeVideoModal}
                    plotterTypeVideoData={
                        updatePlotterTypeVideoModal.getState()?.payload
                    }
                    onSubmit={handleSubmitEdit}
                />
                <ConfirmModal
                    modal={deletePlotterTypeVideoForm.modal}
                    title={t('delete.plotter_type_video.title')}
                    description={t('delete.plotter_type_video.description')}
                    actionLabel={t('action.delete')}
                    onSubmit={deletePlotterTypeVideoForm.form.submitForm}
                    isSubmitting={deletePlotterTypeVideoForm.form.isSubmitting}
                />
                <ConfirmModal
                    modal={activeVideoFormModal}
                    title={t('deactivate.plotter_type_video.title')}
                    description={t('deactivate.plotter_type_video.description')}
                    actionLabel={t('deactivate.plotter_type_video.action')}
                    onSubmit={activeVideoForm.submitForm}
                    isSubmitting={activeVideoForm.isSubmitting}
                />
            </>
        </PageLayout>
    )
}
