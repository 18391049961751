import React, { useMemo, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikErrors, Form, useField } from 'formik'

import {
    DEFAULT_LIST_PER_PAGE,
    QUERY_CACHE_EMPLOYEES_KEY,
} from '../constants/index'
import { useCreateEmployee, useEmployeesQuery, useUpdateEmployee } from '../api'
import { useFlashMessage, useModal } from '../hooks'
import { useQueryClient } from 'react-query'

import { Button, FilterButton } from '../components/ui'

import type {
    EmployeeForm,
    EmployeeListFilterAction,
    EmployeeListFilterState,
    FormSubmitFn,
    ListFilterAction,
    UserForm,
} from '../types'
import { useEmployeeActiveForm } from '../hooks/forms/useEmployeeActiveForm'
import ConfirmModal from './ConfirmModal'
import { EmployeeResponse } from 'api/types'
import EmployeeList from './EmployeeList'
import EmployeeModal from './EmployeeModal'
import {
    RadioGroupField,
    RadioGroupHorizontalGrid,
} from './forms/RadioGroupField'
import { listReducer } from 'helpers'
import EmployeeListFilters from './UserEmployeesListFilters'

const filterReducer = (
    state: EmployeeListFilterState,
    action: EmployeeListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterStatus') {
        return {
            ...state,
            status: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            status: undefined,
            page: 1,
        }
    }

    return listReducer(
        state,
        action as ListFilterAction
    ) as EmployeeListFilterState
}

type UserEmployeesProps = {
    id: string
    values: UserForm
    query: string
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => Promise<void | FormikErrors<UserForm>>
    isSubmitting: boolean
    readonly?: boolean
}

export default function UserEmployees({
    id,
    readonly,
    query,
    isSubmitting,
}: UserEmployeesProps) {
    const { t } = useTranslation()

    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        sort_by: 'id',
        sort_direction: 'desc',
        status: undefined,
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)

    const filterCount = useMemo(() => (filters.status ? 1 : 0), [filters])

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            user_id: id,
            active: filters.status
                ? filters.status.id === 'active'
                    ? 1
                    : 0
                : undefined,
            search_global: query ? query : undefined,
        }
    }, [filters, id, query])

    const employeesQuery = useEmployeesQuery(apiFilters)

    const { form: activeEmployeeForm, modal: activeEmployeeFormModal } =
        useEmployeeActiveForm(() => {
            queryClient.invalidateQueries([QUERY_CACHE_EMPLOYEES_KEY])
        })

    const flashMessage = useFlashMessage()
    const queryClient = useQueryClient()
    const createEmployeeModal = useModal()
    const updateEmployeeModal = useModal<{
        id: number
        payload: EmployeeResponse
    }>()
    const createEmployee = useCreateEmployee()
    const updateEmployee = useUpdateEmployee()

    const handleSubmit: FormSubmitFn<EmployeeForm> = (
        values,
        formikHelpers
    ) => {
        createEmployee.mutate(
            {
                data: {
                    name: values.name,
                    email: values.email,
                    active: values.active,
                    user: parseInt(id, 10),
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.employee.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_EMPLOYEES_KEY,
                    ])
                    createEmployeeModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleSubmitEdit: FormSubmitFn<EmployeeForm> = (
        values,
        formikHelpers
    ) => {
        updateEmployee.mutate(
            {
                id: updateEmployeeModal.getState()!.id,
                data: {
                    name: values.name,
                    email: values.email,
                    active: values.active,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.employee.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_EMPLOYEES_KEY,
                    ])
                    updateEmployeeModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const authValues = [
        {
            value: 1,
            name: t('form.employee.field.required'),
        },
        {
            value: 0,
            name: t('form.employee.field.not_required'),
        },
    ]

    const enableLoginModal = useModal()
    const [, , employeesAuthRequiredHelpers] = useField(
        'employees_auth_required'
    )

    return (
        <>
            <div className="mt-8">
                <div className="text-lg leading-8 font-medium pb-4">
                    {t('form.employee.title')}
                </div>
                <Form>
                    <div className="mr-auto max-w-3xl grid grid-cols-1 gap-4 pb-4">
                        <div className="col-span-1">
                            <RadioGroupField<number>
                                name="employees_auth_required"
                                Grid={RadioGroupHorizontalGrid}
                                disabled={readonly}
                                options={authValues}
                                handleChange={(state) => {
                                    if (state === 1) {
                                        enableLoginModal.openModal()
                                    } else {
                                        employeesAuthRequiredHelpers.setValue(
                                            state
                                        )
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Form>
                <div className="border-t border-gray-600 mt-16"></div>
                <div className="flex items-center justify-end print:hidden pb-4 pt-4">
                    {!readonly && (
                        <span className="ml-2">
                            <Button onClick={createEmployeeModal.openModal}>
                                {t('action.employee.create')}
                            </Button>
                        </span>
                    )}
                    <span className="ml-6">
                        <FilterButton
                            count={filterCount}
                            filtersExpanded={filtersExpanded}
                            disabled={readonly}
                            onClick={() =>
                                setFiltersExpanded((prevState) => !prevState)
                            }
                            handleReset={() =>
                                dispatchFilterAction({
                                    type: 'resetFilters',
                                })
                            }
                        />
                    </span>
                </div>
                <div>
                    {filtersExpanded && (
                        <EmployeeListFilters
                            filters={filters}
                            filterAction={dispatchFilterAction}
                        />
                    )}
                    <EmployeeList
                        filters={filters}
                        filterCount={filterCount}
                        handleToggle={(id, state) => {
                            activeEmployeeFormModal.setState(id)
                            if (state === false) {
                                activeEmployeeForm.setValues({ active: false })
                                activeEmployeeFormModal.openModal()
                            } else {
                                activeEmployeeForm.setValues({ active: true })
                                activeEmployeeForm.submitForm()
                            }
                        }}
                        filterAction={dispatchFilterAction}
                        handleEdit={(id, payload) => {
                            updateEmployeeModal.setState({ id, payload })
                            updateEmployeeModal.openModal()
                        }}
                        employeesQuery={employeesQuery}
                    />
                    <EmployeeModal
                        modal={createEmployeeModal}
                        onSubmit={handleSubmit}
                    />
                    <EmployeeModal
                        modal={updateEmployeeModal}
                        employeeData={updateEmployeeModal.getState()?.payload}
                        onSubmit={handleSubmitEdit}
                    />
                    <ConfirmModal
                        modal={activeEmployeeFormModal}
                        title={t('deactivate.employee.title')}
                        description={t('deactivate.employee.description')}
                        actionLabel={t('deactivate.employee.action')}
                        onSubmit={activeEmployeeForm.submitForm}
                        isSubmitting={activeEmployeeForm.isSubmitting}
                    />
                    <ConfirmModal
                        modal={enableLoginModal}
                        title={t('enable.login.title')}
                        description={t('enable.login.description')}
                        actionLabel={t('enable.login.action')}
                        onSubmit={() => {
                            employeesAuthRequiredHelpers.setValue(1)
                            enableLoginModal.closeModal()
                        }}
                        isSubmitting={false}
                    />
                </div>
            </div>
            {!readonly && (
                <Form>
                    <div className="flex mt-8 pt-5 items-center justify-between">
                        <div className="flex-1"></div>
                        <div className="flex-1 flex items-center justify-end ml-3">
                            <Button variant="secondary" as="link" to="/user">
                                {t('form.cancel')}
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                className="ml-3"
                                loading={isSubmitting}
                            >
                                {t('form.save')}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </>
    )
}
