import React from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'

import { getCxFromStyles } from '../helpers'
import Modal from './Modal'
import { Button, Label } from './ui'

import type { DropdownItem, FormSubmitFn, IModal, PlotterForm } from '../types'

import styles from './Modal.module.scss'
import { useTranslation } from 'react-i18next'
import { DropdownField, TextField } from './forms'
import { PlotterResponse } from '../api/types'

export type PlotterModalProps = {
    modal: IModal
    plotterData?: PlotterResponse
    onSubmit: FormSubmitFn<PlotterForm>
    plotterTypes: DropdownItem[]
}

export default function PlotterModal({
    modal,
    plotterData,
    onSubmit,
    plotterTypes,
}: PlotterModalProps) {
    const cx = getCxFromStyles(styles)
    const { t } = useTranslation()

    const initialValues: PlotterForm = plotterData
        ? {
              name: plotterData.name,
              serial: plotterData.serial,
              description: plotterData.description,
              active: plotterData.active,
              type: plotterTypes.find(
                  ({ id }) => String(plotterData.type?.id) === String(id)
              ),
          }
        : {
              name: '',
              serial: '',
              description: '',
              active: true,
          }

    return (
        <Modal modal={modal} type="default">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg pb-6 leading-6 font-medium text-white text-center"
                >
                    {plotterData
                        ? t('form.plotter.edit_title')
                        : t('form.plotter.title')}
                </DialogTitle>
                <Formik<PlotterForm>
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className="px-2 pb-2">
                            <div className="mr-auto max-w-3xl grid grid-cols-3 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.plotter.field.name')}
                                    </Label>
                                    <TextField name="name" />
                                </div>
                                <div className="col-span-2">
                                    <Label>
                                        {t('form.plotter.field.description')}
                                    </Label>
                                    <TextField name="description" />
                                </div>
                            </div>
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.plotter.field.serial')}
                                    </Label>
                                    <TextField name="serial" />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.plotter.field.type')}
                                    </Label>
                                    <DropdownField
                                        name="type"
                                        value={values.type}
                                        items={plotterTypes}
                                        onChange={(value) =>
                                            setFieldValue('type', value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    {t('form.save')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
