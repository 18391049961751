import { useTranslation } from 'react-i18next'

export function usePlotterSizes() {
    const { t } = useTranslation()

    const sizeValues = [
        {
            value: 'A',
            name: t('plotter_type_sizes.A'),
        },
        {
            value: 'B',
            name: t('plotter_type_sizes.B'),
        },
        {
            value: 'C',
            name: t('plotter_type_sizes.C'),
        },
    ]

    return sizeValues
}
