import React from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import { getCxFromStyles } from '../helpers'
import Modal from './Modal'
import { Button, Label } from './ui'
import { TextField } from './forms'

import type { FormSubmitFn, IModal, PlotterTypeForm } from '../types'

import styles from './Modal.module.scss'
import { PlotterTypeResponse } from '../api/types'
import {
    RadioGroupField,
    RadioGroupHorizontalGrid,
} from './forms/RadioGroupField'
import { usePlotterSizes } from 'hooks/usePlotterSizes'

export type PlotterTypeModalProps = {
    modal: IModal
    plotterTypeData?: PlotterTypeResponse
    onSubmit: FormSubmitFn<PlotterTypeForm>
}

export default function PlotterTypeModal({
    modal,
    plotterTypeData,
    onSubmit,
}: PlotterTypeModalProps) {
    const cx = getCxFromStyles(styles)
    const { t } = useTranslation()

    const sizeValues = usePlotterSizes()

    const initialValues: PlotterTypeForm = plotterTypeData
        ? {
              name: plotterTypeData.name,
              description: plotterTypeData.description,
              size: plotterTypeData.size,
          }
        : {
              name: '',
              description: '',
          }

    return (
        <Modal modal={modal} type="default">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg pb-6 leading-6 font-medium text-white text-center"
                >
                    {plotterTypeData
                        ? t('form.plotter_type.edit_title')
                        : t('form.plotter_type.title')}
                </DialogTitle>
                <Formik<PlotterTypeForm>
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className="px-2 pb-2">
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.plotter_type.field.name')}
                                    </Label>
                                    <TextField name="name" />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t(
                                            'form.plotter_type.field.description'
                                        )}
                                    </Label>
                                    <TextField name="description" />
                                </div>
                            </div>
                            <div className="mr-auto max-w-3xl grid grid-cols-1 gap-4 pb-4">
                                <div className="col-span-1">
                                    <RadioGroupField<string>
                                        name="size"
                                        Grid={RadioGroupHorizontalGrid}
                                        options={sizeValues}
                                    />
                                </div>
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    {t('form.save')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
