import { useMutation, UseMutationOptions } from 'react-query'

import client from '../client'

import type {
    ResponseData,
    ResponseError,
    RecipientResponse,
    UpdaterecipientVariables,
    DeleteVariables,
} from '../types'

const updateRecipient = ({
    id,
    data,
}: UpdaterecipientVariables): Promise<ResponseData<RecipientResponse>> =>
    client.patch(`/report-recipients/${id}`, data)

export const useUpdateRecipient = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<RecipientResponse>,
            ResponseError,
            UpdaterecipientVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => updateRecipient(data), options)

const deleteRecipient = ({
    id,
}: DeleteVariables): Promise<ResponseData<void>> =>
    client.delete(`/report-recipients/${id}`)

export const useDeleteRecipient = (
    options?: Omit<
        UseMutationOptions<ResponseData<void>, ResponseError, DeleteVariables>,
        'mutationFn'
    >
) => useMutation((data) => deleteRecipient(data), options)
