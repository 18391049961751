import React from 'react'
import { useField } from 'formik'

import ErrorMessage from './ErrorMessage'
import FileUploader, { UploaderState } from 'components/FileUploader'
import { Accept } from 'react-dropzone'

interface IAddedFile {
    file: any
    title: string
}

export default function FileUploaderField({
    name,
    trailingAddOn,
    showErrorMessage = true,
    ...props
}: {
    name: string
    trailingAddOn?: string
    showErrorMessage?: boolean
    acceptFormats: Accept
    limit?: number
    fileId: number
    isMultiple: boolean
    handleUploadSuccess: (state: UploaderState) => void
    handleRemoveFile: () => void
    addedFile?: IAddedFile
}) {
    const [field, meta] = useField(name)

    return (
        <>
            <FileUploader
                hasError={showErrorMessage && !!meta.error}
                {...props}
            />
            {showErrorMessage && <ErrorMessage name={field.name} />}
        </>
    )
}
