import React from 'react'
import { useDropzone, Accept, FileRejection, DropEvent } from 'react-dropzone'
import { DocumentDuplicateIcon } from '@heroicons/react/outline'

import { getCxFromStyles } from 'helpers'

import styles from './Dropzone.module.scss'
import { useTranslation } from 'react-i18next'

const Dropzone = ({
    acceptFormats = { 'image/png': ['.png'] },
    limit = 2,
    disableClick,
    disabled,
    isMultiple,
    hasError,
    className,
    handleUpload,
}: React.PropsWithChildren<{
    acceptFormats: Accept
    className?: string
    disableClick?: boolean
    hasError?: boolean
    isMultiple: boolean
    disabled?: boolean
    limit?: number
    handleUpload: (
        acceptedFiles: File[],
        fileRejections: FileRejection[],
        event: DropEvent
    ) => void
}>) => {
    const { t } = useTranslation()
    const cx = getCxFromStyles(styles)

    const { getRootProps, isDragActive, isDragAccept, isDragReject } =
        useDropzone({
            accept: acceptFormats,
            disabled,
            multiple: isMultiple,
            noClick: disableClick,
            maxSize: 500 * 1024 * 1024,
            onDrop: handleUpload,
        })

    const files = Object.entries(acceptFormats)
        .map(([_, value]) => value.join(', '))
        .join(', ')
        .replace(/\.*/g, '')
        .toUpperCase()

    return (
        <div
            {...getRootProps()}
            className={cx(
                'dropzone',
                {
                    isDragActive: isDragActive,
                    isDragAccept: isDragAccept,
                    isDragReject: isDragReject,
                    error: hasError,
                },
                className
            )}
        >
            <div className="self-center space-y-1 text-center">
                <DocumentDuplicateIcon
                    strokeWidth={1}
                    className="mx-auto w-12 text-red-600"
                />
                <div className="flex text-white">
                    <span className="text-sm leading-5 font-medium text-red-600 px-[6px] py-[3px] rounded-lg bg-red-100">
                        {t('dropzone.text_bold')}
                    </span>
                    <p className="pl-1 py-[3px] text-sm leading-5 font-medium">
                        {t('dropzone.text_normal')}
                    </p>
                </div>
                <p className="text-gray-300 text-xs leading-4 font-normal">
                    {files}
                    {t('dropzone.limit', { limit })}
                </p>
            </div>
        </div>
    )
}

export default Dropzone
